<template>
  <div class="pa-5">
    <div class="caption text--secondary">
      {{ observatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ observatory.lat }}, {{ observatory.lng }}
    </div>
    <div v-if="observatory.mesuredData.length === 0">
      この観測所は、休止もしくは欠測しています。
    </div>
    <div v-else class="d-flex">
      <v-card class="caption mr-auto" flat>
        (mm)
      </v-card>
      <v-card class="caption" flat>
        (mm/10分)
      </v-card>
    </div>
    <observatory-rain-chart
      v-if="observatory.mesuredData.length > 0"
      :selected-observatory="observatory"
      :height="250"
    />
    <div class="text-right body-2 mt-2">
      {{ currentDateTime }}
    </div>
    <v-simple-table dense class="mt-3 timeseries">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left">10分</th>
            <th class="text-left">累加</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ts, i) in timeSeries" :key="i">
            <td>{{ ts.time }}</td>
            <td>{{ ts.min10 }}</td>
            <td>{{ ts.total }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ObservatoryRainChart from "./RainChart";
import moment from "moment";

export default {
  name: "RainDetail",
  components: { ObservatoryRainChart },
  computed: {
    observatory() {
      return this.$store.getters.selectedObservatory;
    },
    currentDateTime() {
      if (this.observatory.mesuredData.length === 0) {
        return "";
      }
      const now = this.observatory.mesuredData[
        this.observatory.mesuredData.length - 1
      ].date;
      return moment
        .utc(now, "YYYY/MM/DD HH:mm")
        .local()
        .format("YYYY/MM/DD HH:mm");
    },
    timeSeries() {
      return this.observatory.mesuredData.map(r => {
        const time = moment
          .utc(r.date, "YYYY/MM/DD HH:mm")
          .local()
          .format("MM/DD HH:mm");
        return {
          time: time,
          min10: r.min10 !== undefined ? parseFloat(r.min10) : "---",
          total: r.total !== undefined ? parseFloat(r.total) : "---"
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.timeseries {
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .std-color {
    width: 16px;
    height: 12px;
    border-bottom: white 3px solid;
  }
  .predict {
    color: blue;
  }
}
</style>
