<template>
  <l-map
    ref="leafletMap"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
    :zoom="zoom"
    :center="centerPosition"
    :options="options"
    :zoom-animation="false"
    style="height: 100%"
  >
    <l-control position="topleft" style="pointer-events: auto">
      <RiskControlLayer />
      <base-tile-control-layer class="mt-2" />
      <terrain-features-control-layer class="mt-2" />
      <BranchOfficeControl class="mt-2" v-if="false" />
    </l-control>

    <base-tile-layer />
    <terrain-feature-layer />
    <observatory-layer />
    <BranchOfficeLayer v-if="false" />
    <CityBorderLayer />

    <l-tile-layer
      layer-type="overlay"
      url="https://s3.ap-northeast-1.amazonaws.com/ctie.hachioji.web/static/tile/river/{z}/{x}/{y}.png"
    ></l-tile-layer>

    <template v-if="selectedRisk.key === Risk.RAIN.key">
      <rain-layer />
    </template>
    <template v-if="selectedRisk.key === Risk.RIVER_FLOODING.key">
      <river-flooding-layer />
    </template>
    <template v-if="selectedRisk.key === Risk.DOSYA.key">
      <dosya-layer />
    </template>

    <l-control-scale
      v-if="false"
      position="topright"
      :imperial="false"
      :metric="true"
    />
    <l-control position="topright">
      <observatory-usage-guide />
      <usage-guide-layer />
    </l-control>
    <l-control position="topright">
      <zoom-control />
    </l-control>
    <l-control position="bottomleft">
      <time-series-control-layer
        @show-datetime="changeWheelZoom(false)"
        @close-datetime="changeWheelZoom(true)"
      />
    </l-control>
  </l-map>
</template>

<script>
import { LMap, LControl, LControlScale, LTileLayer } from "vue2-leaflet";
import L from "leaflet";

import BaseTileLayer from "./layers/BaseTileLayer";
import ObservatoryLayer from "./layers/ObservatoryLayer";
import { Risk } from "../../enums/Risk";
import TimeSeriesControlLayer from "./layers/TimeSeriesControlLayer";
import RainLayer from "./layers/RainLayer";
import RiskControlLayer from "./layers/RiskControlLayer";
import RiverFloodingLayer from "./layers/RiverFloodingLayer";
import DosyaLayer from "./layers/DosyaLayer";
import BaseTileControlLayer from "./layers/BaseTileControlLayer";
import TerrainFeaturesControlLayer from "./layers/TerrainFeaturesControlLayer";
import ZoomControl from "./layers/ZoomControl";
import UsageGuideLayer from "./layers/UsageGuideLayer";
import ObservatoryUsageGuide from "./layers/ObservatoryUsageGuide";
import TerrainFeatureLayer from "./layers/TerrainFeatureLayer";
import { BRANCH_OFFICE } from "../../enums/BranchOffice";
import BranchOfficeControl from "./layers/BranchOfficeControl";
import BranchOfficeLayer from "./layers/BranchOfficeLayer";
import { MapPane } from "../../enums/Type";
import CityBorderLayer from "./layers/CityBorderLayer";

export default {
  name: "BaseMap",
  components: {
    TerrainFeatureLayer,
    ObservatoryUsageGuide,
    UsageGuideLayer,
    ZoomControl,
    RainLayer,
    LMap,
    BaseTileLayer,
    LControl,
    ObservatoryLayer,
    TimeSeriesControlLayer,
    RiskControlLayer,
    LControlScale,
    RiverFloodingLayer,
    DosyaLayer,
    BaseTileControlLayer,
    TerrainFeaturesControlLayer,
    BranchOfficeControl,
    BranchOfficeLayer,
    CityBorderLayer,
    LTileLayer
  },
  data() {
    return {
      Risk,
      minZoom: 6,
      maxZoom: 18,
      options: { zoomControl: false, scrollWheelZoom: true },
      zoom: 14,
      centerPosition: new L.LatLng(BRANCH_OFFICE[0].lat, BRANCH_OFFICE[0].lng)
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    isShowMapDetail() {
      return this.$store.getters.isShowMapDetail;
    },
    isRightNavVisible() {
      return this.$store.getters.isRightNavVisible;
    },
    isMonitoring() {
      return this.$store.getters.isMonitoring;
    },
    isCameraMonitor() {
      return this.$store.getters.isCameraMonitor;
    }
  },
  watch: {
    isShowMapDetail() {
      this.redraw();
    },
    isRightNavVisible() {
      if (this.isRightNavVisible === false) {
        this.redraw();
      }
    },
    isMonitoring() {
      if (this.isMonitoring === false) {
        this.redraw();
      }
    },
    isCameraMonitor() {
      if (this.isCameraMonitor === false) {
        this.redraw();
      }
    }
  },
  created() {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  },
  mounted() {
    const map = this.$refs.leafletMap.mapObject;
    this.$store.commit("SET_LEAFLET_MAP", map);
    if (map) {
      Object.values(MapPane).forEach(pane => {
        if (map.getPane(pane.name) === undefined) {
          map.createPane(pane.name).style.zIndex = pane.zIndex;
        }
      });
    }
  },
  methods: {
    changeWheelZoom(enable) {
      if (enable === false) {
        this.$refs.leafletMap.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.leafletMap.mapObject.scrollWheelZoom.enable();
      }
    },
    redraw() {
      setTimeout(() => {
        this.centerPosition = this.$refs.leafletMap.mapObject.getCenter();
        this.$refs.leafletMap.mapObject.invalidateSize();
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.leaflet-top.leaflet-right {
  pointer-events: none;
  .leaflet-control {
    pointer-events: none;
  }
}
.leaflet-top.leaflet-left {
  .leaflet-control {
    text-align: left;
  }
}
.leaflet-bottom.leaflet-left {
  pointer-events: painted;
  width: 100%;
  .leaflet-control {
    pointer-events: painted;
    width: 100%;
  }
}
</style>
<style lang="scss">
.leaflet-image-layer {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  background-color: rgba(0, 0, 0, 0.1);
}

.riskImage {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  background-color: rgba(0, 0, 0, 0.1);
}

.read-only {
  pointer-events: none;
}
.leaflet-bottom.leaflet-left {
  z-index: 7000 !important;
}
.wind-velocity {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  > * {
    position: relative;
    clear: both;
    float: left;
    margin: 0 10px 10px 10px;
  }
}
</style>
