<template>
  <div style="pointer-events: auto">
    <h5>レイヤー透明度</h5>
    <v-slider v-model="opacity" hide-details :step="0.1" :max="1" :min="0">
    </v-slider>
  </div>
</template>

<script>
export default {
  name: "LayerOpacityControl",
  data() {
    return {
      opacity: 0.7
    };
  },
  watch: {
    opacity(value) {
      this.$store.commit("SET_RISK_SUB_CONTROL", { opacity: value });
    }
  }
};
</script>

<style scoped></style>
