<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="close()">
        <div class="modal-container">
          <div class="modal-body">
            <h6>観測所データ</h6>
            <v-row no-gutters>
              <v-col cols="6" @click="switchMobileObservatoryRisk()">
                <v-card
                  class="menu-button"
                  :class="{ active: isMobileObservatoryRisk }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-text">
                      <v-icon small>{{ Risk.OBSERVATORY.icon }}</v-icon>
                      {{ Risk.OBSERVATORY.title }}
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="6" @click="showMobileCameraMonitor()">
                <v-card class="menu-button">
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-text">
                      <v-icon small>mdi-camera</v-icon>
                      カメラ一覧
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <h6>表示データ</h6>
            <v-row no-gutters>
              <template v-for="risk in Risk">
                <v-col
                  :key="risk.index"
                  cols="6"
                  @click.once="selectRisk(risk)"
                  v-if="
                    risk.index > 0 &&
                      risk.isShowMobile === true &&
                      (isPrivate === true || risk.key != 'riverFlooding')
                  "
                >
                  <v-card
                    class="menu-button"
                    :class="{ active: risk.key === selectedRisk.key }"
                  >
                    <v-img class="white--text align-end" :gradient="gradient">
                      <v-card-text class="menu-text">
                        <v-icon small>{{ risk.icon }}</v-icon>
                        {{ risk.title }}
                      </v-card-text>
                    </v-img>
                  </v-card>
                </v-col>
              </template>
            </v-row>
            <v-card flat tile v-if="false">
              <v-switch
                v-model="isMobileObservatoryRisk"
                style="width: 150px"
                class="observatory-switch my-1"
                dense
                label="観測所の表示"
                @change="switchMobileObservatoryRisk"
              />
            </v-card>

            <h6>地図</h6>
            <v-row dense no-gutters>
              <v-col
                :key="tile.index"
                cols="6"
                @click.once="selectBaseTile(tile)"
                v-for="tile in BaseTile"
              >
                <v-card
                  class="menu-button"
                  :class="{ active: tile.key === selectedBaseTile.key }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-text">
                      <v-icon small>mdi-map</v-icon>{{ tile.string }}
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>

            <h6>ハザードマップ</h6>
            <v-row dense no-gutters>
              <v-col
                :key="index"
                cols="6"
                @click.once="selectHazardMap(feature)"
                v-for="(feature, index) in Feature"
              >
                <v-card
                  class="menu-button"
                  :class="{ active: feature.key === mobileHazardMap.key }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-hazard" v-html="feature.label">
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <div class="d-flex">
              <v-btn text small @click="showManual()">マニュアル</v-btn>
              <v-btn
                color="primary"
                class="ml-auto"
                text
                small
                @click="close()"
              >
                閉じる
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Feature } from "../../../enums/Feature";
import { Risk } from "../../../enums/Risk";
import { BaseTile } from "../../../enums/BaseTile";

export default {
  name: "MapMenu",
  data() {
    return {
      Feature,
      Risk,
      BaseTile,
      gradient: "to bottom, rgba(50, 50, 50, 0.1), rgba(50, 50, 50, 0.5)",
      isMobileObservatoryRisk: true,
      cameraMonitorWindow: null
    };
  },
  computed: {
    leafletMap() {
      return this.$store.getters.leafletMap;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    selectedBaseTile() {
      return this.$store.getters.selectedBaseTile;
    },
    mobileHazardMap() {
      return this.$store.getters.mobileHazardMap;
    },
    isShowMobileHazardMap() {
      return this.$store.getters.isShowMobileHazardMap;
    },
    isPrivate() {
      return this.$store.getters.isPublic !== true;
    }
  },
  mounted() {
    this.isMobileObservatoryRisk = this.$store.getters.isMobileObservatoryRisk;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectHazardMap(feature) {
      if (
        this.mobileHazardMap.key &&
        this.mobileHazardMap.key === feature.key
      ) {
        this.$store.commit("SET_IS_SHOW_MOBILE_HAZARD_MAP", false);
        this.$store.commit("SET_MOBILE_HAZARD_MAP", {
          key: null,
          detail: []
        });
      } else {
        this.$store.commit("SET_IS_SHOW_MOBILE_HAZARD_MAP", true);
        this.$store.commit("SET_MOBILE_HAZARD_MAP", {
          key: feature.key,
          detail: feature
        });
      }
      this.close();
    },
    selectRisk(riskInfo) {
      if (
        riskInfo.key === Risk.TYPHOON.key ||
        riskInfo.key === Risk.DISASTER_PREVENTION.key
      ) {
        window.open(riskInfo.url);
      } else {
        if (riskInfo.key === this.selectedRisk.key) {
          this.$store.commit("SET_SELECTED_RISK", {});
        } else {
          this.$store.commit("SET_SELECTED_RISK", riskInfo);
        }
      }
      this.close();
    },
    selectBaseTile(baseTileInfo) {
      this.$store.commit("SET_SELECTED_BASE_TILE", baseTileInfo);
      this.close();
    },
    showChat() {
      window.open("#/mobile/chat", "riskma-date-chat");
      this.close();
    },
    showManual() {
      window.open("https://hachioji.riskma.jp/manual_mobile.pdf", "_blank");
    },
    switchMobileObservatoryRisk() {
      this.isMobileObservatoryRisk = !this.isMobileObservatoryRisk;
      this.$store.commit(
        "SET_IS_MOBILE_OBSERVATORY_RISK",
        this.isMobileObservatoryRisk
      );
      this.close();
    },
    showMobileCameraMonitor() {
      const url = "/#/mobile-camera-monitor";
      if (
        this.cameraMonitorWindow === null ||
        this.cameraMonitorWindow.closed === true
      ) {
        this.cameraMonitorWindow = window.open(url);
      } else {
        this.cameraMonitorWindow.location.href = url;
        setTimeout(() => {
          this.cameraMonitorWindow.focus();
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss">
.menu-button {
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgb(122, 162, 236) !important;
  &.active {
    background-color: rgb(47, 94, 182) !important;
  }
  .v-icon {
    padding-right: 3px;
  }
  v-img {
    height: 100%;
  }
  .menu-text {
    padding: 3px;
    font-size: 11px;
  }
  .menu-hazard {
    padding: 3px 5px 3px 10px;
    line-height: 14px;
    height: 32px;
    font-size: 10px;
    white-space: pre;
  }
}

.observatory-switch.v-input--switch .v-label {
  font-size: 12px;
  font-weight: bold;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 310px;
  margin: 0px auto;
  padding: 10px 5px 10px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
