<template>
  <v-card
    class="observatory-control pa-1"
    width="140"
    color="rgba(255, 255, 255, 0.9)"
  >
    <v-row no-gutters align="center">
      <v-col>
        <v-checkbox
          class="checkbox"
          hide-details
          dense
          v-model="enabledObservatories"
          :label="OBSERVATORY.STAGE.name"
          :value="OBSERVATORY.STAGE.code"
          @change="onChangeFilters"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-icon size="16">mdi-triangle</v-icon>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <v-checkbox
          class="checkbox"
          hide-details
          dense
          v-model="enabledObservatories"
          :label="OBSERVATORY.RAIN.name"
          :value="OBSERVATORY.RAIN.code"
          @change="onChangeFilters"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-icon size="16">mdi-checkbox-blank-circle</v-icon>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <v-checkbox
          class="checkbox"
          hide-details
          dense
          v-model="enabledObservatories"
          :label="OBSERVATORY.CAMERA.name"
          :value="OBSERVATORY.CAMERA.code"
          @change="onChangeFilters"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-icon size="18">mdi-camera</v-icon>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <v-checkbox
          class="checkbox"
          hide-details
          dense
          v-model="enabledObservatories"
          :label="OBSERVATORY.LIVECAMERA.name"
          :value="OBSERVATORY.LIVECAMERA.code"
          @change="onChangeFilters"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-icon size="18">mdi-camera-iris</v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { OBSERVATORY } from "../../../enums/Type";

export default {
  name: "ObservatoryControlLayer",
  data: () => ({
    OBSERVATORY,
    enabledObservatories: [
      OBSERVATORY.STAGE.code,
      OBSERVATORY.RAIN.code,
      OBSERVATORY.DAM.code,
      OBSERVATORY.CAMERA.code,
      OBSERVATORY.LIVECAMERA.code
    ],
    histories: [],
    selectWaterItem: 0
  }),
  mounted() {
    this.enabledObservatories = this.$store.getters.riskSubControl.observatory;
    this.onChangeFilters();
  },
  methods: {
    onChangeFilters() {
      this.$store.commit("SET_RISK_SUB_CONTROL", {
        observatory: this.enabledObservatories
      });
    }
  }
};
</script>
<style lang="scss">
.checkbox {
  .v-label {
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.observatory-control {
  pointer-events: painted;
  .v-input--selection-controls {
    margin-top: 0;
  }
}
</style>
