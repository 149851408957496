/* eslint-disable prettier/prettier */
const staticConfig = {
  tileServer: {
    url: "https://cyberjapandata.gsi.go.jp"
  }
};

const cognitoConfig = {
  Region: "ap-northeast-1",
  UserPoolId: "ap-northeast-1_bG5C5oRmz",
  ClientId: "48buti1nr5lqmcvcivfenhnamj"
};

const apiBaseUrl = {
  "hachioji-shokuin.riskma.jp": {
    development: "https://1q2eg1pv0c.execute-api.ap-northeast-1.amazonaws.com/dev",
    production: "https://d3tk9bfu8frsm3.cloudfront.net"
  },
  "hachioji.riskma.jp": {
    development: "https://906efo8xw7.execute-api.ap-northeast-1.amazonaws.com/dev",
    production: "https://d12lthd3s9qvl.cloudfront.net"
  }
};

export { staticConfig, apiBaseUrl, cognitoConfig };
