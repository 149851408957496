<template>
  <div class="base-tile-control">
    <custom-expansion-panel
      :data="[panelData]"
      @change="callback"
    ></custom-expansion-panel>
  </div>
</template>

<script>
import _ from "lodash";

import { BaseTile } from "../../../enums/BaseTile";
import CustomExpansionPanel from "./CustomExpansionPanel";

export default {
  name: "BaseTileControlLayer",
  components: { CustomExpansionPanel },
  data() {
    return {
      panelData: {
        label: "タイル",
        selectedItem: {},
        items: []
      }
    };
  },
  created() {
    _.forEach(BaseTile, baseTile => {
      if (typeof baseTile === "object") {
        this.panelData.items.push({
          label: baseTile.string,
          value: baseTile
        });
      }
    });

    this.panelData.selectedItem = _.find(this.panelData.items, o => {
      return this.$store.getters.selectedBaseTile === o.value;
    });
  },
  methods: {
    callback(selectedItem) {
      const selectedBaseTile = selectedItem.value;
      this.$store.commit("SET_SELECTED_BASE_TILE", selectedBaseTile);
    }
  }
};
</script>
<style lang="scss" scoped>
.base-tile-control {
  width: 140px;
}
</style>
