<template>
  <div v-if="selectedImageUrl">
    <template v-for="(img, index) in slicedImages">
      <l-marker
        :key="index"
        :lat-lng="[img.markerLat, img.markerLng]"
        v-if="img.isInDeviceViewPort"
      >
        <l-icon :icon-anchor="[0, 0]">
          <div
            :style="{
              width: img.divWidth + 'px',
              height: img.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none',
              border: bordersColor ? `1px solid ${bordersColor}` : 'none'
            }"
          >
            <img
              class="riskImage"
              alt="rainfallImage"
              :src="selectedImageUrl"
              :style="{
                transform: `translate(0,${img.translateY}px)`,
                width: img.imgWidth + 'px',
                height: img.imgHeight + 'px',
                'pointer-events': 'none',
                opacity: opacity
              }"
            />
          </div>
        </l-icon>
      </l-marker>
    </template>
  </div>
</template>
<script>
import { LMarker, LIcon } from "vue2-leaflet";
import moment from "moment";

export default {
  name: "SlicedImageLayer",
  components: {
    LMarker,
    LIcon
  },
  data() {
    return {
      bordersColor: null, // デバッグ色
      iewportBottomLat: 0,
      viewportTopLat: 0
    };
  },
  props: {
    bounds: {
      type: [Array, Object],
      required: true
    },
    images: {
      type: [Array, Object],
      required: true
    }
  },

  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    opacity() {
      return this.$store.getters.riskSubControl.opacity;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedImage() {
      if (!this.images) {
        return null;
      }
      if (this.images.length <= 0) {
        return null;
      }
      const finded = this.images.find(info =>
        this.selectedDate.isSame(moment.utc(info.date, "YYYY/MM/DD HH:mm"))
      );
      return finded === undefined ? null : finded;
    },
    selectedImageUrl() {
      if (this.selectedImage === null) {
        return null;
      } else {
        return this.selectedImage.image !== undefined
          ? this.selectedImage.image.src
          : this.selectedImage.url;
      }
    },
    selectedImageType() {
      if (
        this.selectedImage === null ||
        this.selectedImage.type === undefined
      ) {
        return null;
      } else {
        return this.selectedImage.type;
      }
    },
    slicedImages() {
      if (!this.map) {
        return [];
      }
      if (!this.images || this.images.length <= 0) {
        return [];
      }
      const imagesInfo = [];
      const bounds =
        this.selectedImageType !== null &&
        Object.keys(this.bounds).includes(this.selectedImageType)
          ? this.bounds[this.selectedImageType]
          : this.bounds;
      const coordinate = {
        north: bounds[0][0],
        west: bounds[0][1],
        south: bounds[1][0],
        east: bounds[1][1]
      };
      const sliceCount = this.selectedImageType === "FORECAST" ? 2 : 50;
      const slicedLatHeight =
        (coordinate.north - coordinate.south) / sliceCount;
      for (let i = 0; i < sliceCount; i++) {
        const slicedImageBottomLeftPoint = this.map.project([
          coordinate.north - i * slicedLatHeight - slicedLatHeight,
          coordinate.west
        ]);
        const slicedImageTopRightPoint = this.map.project([
          coordinate.north - i * slicedLatHeight,
          coordinate.east
        ]);
        let slicedImageHeight =
          slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
        let slicedImageWidth =
          slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;
        if (
          Math.round(slicedImageBottomLeftPoint.y) <
          Math.round(
            slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
          )
        ) {
          slicedImageHeight -= -1;
        }
        const markerLat = coordinate.north - i * slicedLatHeight;
        const markerLng = coordinate.west;
        const divHeight = Math.ceil(slicedImageHeight) - 1.4;
        imagesInfo.push({
          markerLat: markerLat,
          markerLng: markerLng,
          divWidth: slicedImageWidth,
          divHeight: divHeight,
          imgWidth: slicedImageWidth,
          imgHeight: slicedImageHeight * sliceCount,
          translateY: -(i * slicedImageHeight + 1),
          isInDeviceViewPort: this.isElementInDeviceViewPort(
            markerLat,
            markerLng,
            divHeight
          )
        });
      }
      return imagesInfo;
    }
  },
  watch: {
    images() {
      this.updateViewportLatitude();
    }
  },
  created() {
    this.updateViewportLatitude();
  },
  mounted() {
    this.map.on("moveend", this.updateViewportLatitude);
  },
  beforeDestroy() {
    this.map.off("moveend", this.updateViewportLatitude);
  },
  methods: {
    /**
     * 現在表示中画面（viewport）の最新緯度を取得する
     * @return {void}
     */
    updateViewportLatitude() {
      this.viewportBottomLat = this.getScreenBottomLat();
      this.viewportTopLat = this.getScreenTopLat();
    },
    /**
     * 現在表示中画面（viewport）の下の緯度取得
     * @return {latitude}
     */
    getScreenBottomLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMinY = [pointC.x, pointC.y - window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMinY);
      return latlng.lat;
    },
    /**
     * 現在表示中画面（viewport）の上のの緯度取得
     * @return {latitude}
     */
    getScreenTopLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMaxY = [pointC.x, pointC.y + window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMaxY);
      return latlng.lat;
    },
    /**
     * DOMエレメントが現在デバイス画面（viewport）内で表示されているか確認する
     * @param {latitude} elementBottomLat DOMエレメントの緯度
     * @param {longitude} elementBottomLng DOMエレメントの軽度
     * @param {float} elementHeightPx DOMエレメントの高さ（px）
     * @return {bool} true: デバイス画面内に表示されている、false: 画面外表示されている
     */
    isElementInDeviceViewPort(
      elementBottomLat,
      elementBottomLng,
      elementHeightPx
    ) {
      const elementTopLat = this.getElemenTopLat(
        [elementBottomLat, elementBottomLng],
        elementHeightPx
      );
      if (
        elementTopLat < this.viewportBottomLat &&
        elementTopLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat < this.viewportBottomLat &&
        elementBottomLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat >= this.viewportBottomLat &&
        elementTopLat <= this.viewportTopLat
      ) {
        return true;
      }
      return false;
    },
    /**
     * エレメントの上のの緯度取得
     * @param {Array<float>} elementTopLatLng
     * @param {float} divHeight
     * @return {latitude}
     */
    getElemenTopLat(elementTopLatLng, elementHeightPx) {
      let pointDivTop = this.map.latLngToContainerPoint(elementTopLatLng);
      let pointY = [pointDivTop.x, pointDivTop.y + elementHeightPx];
      let latlng = this.map.containerPointToLatLng(pointY);
      return latlng.lat;
    }
  }
};
</script>
