<template>
  <div class="camera-monitor">
    <v-card
      :width="monitorWidth"
      class="wrap-container overflow-y-auto d-flex flex-wrap"
      height="100vh"
      tile
      flat
      color="#F5F5F5"
    >
      <v-card
        class="pt-2 px-2 d-flex"
        :width="monitorWidth"
        tile
        flat
        color="#F5F5F5"
      >
        <div class="caption font-weight-bold">カメラ一覧</div>
        <v-btn class="ml-auto mr-2" x-small icon @click="close()">
          <v-icon x-small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
      <v-card
        v-for="station in stations"
        :key="station._id"
        class="pa-2"
        :width="cardWidth"
        tile
        flat
        color="#F5F5F5"
      >
        <div class="caption station-area" @click="moveStation(station)">
          <v-card height="50" flat tile>
            <div>{{ station.name }}</div>
            <div v-if="station.address">{{ station.address }}</div>
          </v-card>
          <v-img
            v-if="station.url"
            width="220"
            height="120"
            :src="station.url"
          />
          <v-img
            v-else
            width="220"
            height="120"
            :src="require('@/assets/images/camera-none.jpg')"
            contain
          />
          <div>{{ station.date }}</div>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      content-class="camera-dialog"
      v-model="dialog"
      max-width="1100"
      style="z-index: 8100"
      @click="dialog = false"
    >
      <v-img :src="targetUrl" contain @click="dialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import * as axiosHelper from "../utils/axiosHelper";
import L from "leaflet";
import moment from "moment";
export default {
  name: "CameraMonitor",
  components: {},
  data() {
    return {
      cardWidth: 250,
      windowWidth: window.innerWidth,
      baseStations: [],
      stations: [],
      targetUrl: "",
      dialog: false
    };
  },

  computed: {
    cameras() {
      const observatories = this.$store.getters.observatories;
      const cameras = observatories.filter(obs => obs.type === 30);
      cameras.sort((a, b) => {
        // 緯度降順
        if (a.lat > b.lat) return -1;
        if (a.lat < b.lat) return 1;
        return 0;
      });
      return cameras;
    },
    rowPanelNum() {
      const num = Math.floor((this.windowWidth - 500) / this.cardWidth);
      if (num < 1) {
        return 1;
      } else {
        return num;
      }
    },
    monitorWidth() {
      return this.cardWidth * this.rowPanelNum + 5;
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },

  watch: {
    baseDate(newval, oldval) {
      if (!newval.isSame(oldval)) {
        this.sync();
      }
    },
    rowPanelNum(newval, oldval) {
      if (newval !== oldval) {
        this.refreshStations();
      }
    }
  },

  created() {
    window.addEventListener("resize", this.resizeWindow);
    this.sync();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
  },

  mounted() {
    //
  },

  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    close() {
      this.$store.commit("SET_IS_CAMERA_MONITOR", false);
    },
    moveStation(station) {
      this.map.panTo(new L.LatLng(station.lat, station.lng));
      if (this.map.getZoom() < 14) {
        setTimeout(() => {
          this.map.setZoom(14);
        }, 500);
      }
      this.targetUrl = station.url;
      this.dialog = true;
    },
    refreshStations() {
      this.stations = [];
      let targets = [];
      this.baseStations.forEach(station => {
        targets.push(station);
        targets.sort((a, b) => {
          // 経度昇順
          if (a.lng > b.lng) return 1;
          if (a.lng < b.lng) return -1;
          return 0;
        });
        if (targets.length >= this.rowPanelNum) {
          this.stations = this.stations.concat(targets);
          targets = [];
        }
      });
      if (targets.length > 0) {
        this.stations = this.stations.concat(targets);
      }
    },
    async sync() {
      this.stations = [];
      this.baseStations = [];
      const processes = this.cameras.map(target => {
        return (async () => {
          const params = {
            date: this.baseDate.format("YYYY/MM/DD HH:mm"),
            id: target._id,
            type: 30
          };
          const row = { ...target };
          row.date = "";
          row.url = "";
          this.baseStations.push(row);
          const response = await axiosHelper.get(
            "/api/observatories/data",
            params
          );
          if (response !== null && response.observatoryData) {
            const cameraData = response.observatoryData[0].cameraData;
            if (cameraData && cameraData.length > 0) {
              const latest = cameraData[cameraData.length - 1];
              row.date = moment
                .utc(latest.date)
                .local()
                .format("YYYY年M月D日 HH時mm分");
              row.url = latest.url;
            }
          }
        })();
      });
      await Promise.all(processes);
      this.refreshStations();
    }
  }
};
</script>

<style lang="scss" scoped>
.station-area {
  background-color: white;
  padding: 8px 14px;
  cursor: pointer;
  border: 1px solid rgb(228, 228, 228);
}
.camera-dialog {
  box-shadow: none !important;
}
</style>
