<template>
  <v-navigation-drawer
    class="fill-height"
    permanent
    app
    right
    width="320"
    style="background-color: #2c3e50"
    v-if="!isShowMapDetail"
  >
    <div class="text-right">
      <v-btn class="mt-1 mr-1" dark x-small icon @click="close()">
        <v-icon dark x-small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-list-item dense>
      <v-list-item-content>
        <v-card height="45" color="transparent" tile flat>
          <h3 class="white--text text-center">
            八王子市流域水災害リスク情報
          </h3>
          <div v-if="isPrivate" class="caption white--text text-center">
            （職員向け）
          </div>
        </v-card>
        <v-list-item-title class="title" style="color: white">
          <img :src="AssetsImage" height="45" width="100%" />
        </v-list-item-title>
        <v-row no-gutters class="py-2">
          <v-col cols="12">
            <datetime
              style="font-size: 13px"
              type="datetime"
              v-model="datetimeValue"
              :max-datetime="currentDateTimeValue"
            >
              <template slot="button-cancel">
                <v-btn text color="error">
                  キャンセル
                </v-btn>
              </template>
              <template slot="button-confirm" slot-scope="scope">
                <span v-if="scope.step === 'date'">
                  <v-btn icon color="indigo">
                    <v-icon>mdi-clock</v-icon>
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn text color="success">
                    確定
                  </v-btn>
                </span>
              </template>
            </datetime>
          </v-col>
          <v-col cols="12" class="pt-1" style="text-align: center">
            <v-btn color="primary" small @click="updateLatest()">
              <v-icon small class="mr-1">mdi-update</v-icon>
              最新時刻に更新
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <div style="height: calc(100% - 205px);">
      <alert-information />
    </div>
  </v-navigation-drawer>
</template>

<script>
import moment from "moment";
import AlertInformation from "./AlertInformation";
import AssetsImage from "@/assets/LogoLarge.svg";

export default {
  name: "RightSideContents",
  components: {
    AlertInformation
  },
  data() {
    return {
      AssetsImage,
      currentDateTimeValue: moment.utc().toISOString(),
      datetimeValue: moment.utc().toISOString()
    };
  },
  watch: {
    datetimeValue(value) {
      const _moment = moment.utc(value);
      this.$store.commit("SET_BASE_DATE", _moment);
    },
    baseDate(_moment) {
      this.datetimeValue = _moment.toISOString();
    }
  },
  computed: {
    isShowMapDetail() {
      return this.$store.getters.isShowMapDetail;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    isPrivate() {
      return this.$store.getters.isPublic !== true;
    }
  },
  methods: {
    updateLatest() {
      const currentMoment = moment.utc();
      this.currentDateTimeValue = currentMoment.toISOString();
      this.datetimeValue = currentMoment.toISOString();
      this.$store.commit("SET_BASE_DATE", moment.utc());
    },
    close() {
      this.$store.commit("SET_IS_RIGHT_NAV", false);
    }
  }
};
</script>

<style lang="scss">
.vdatetime {
  height: 100%;
}
.vdatetime-input {
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
}
</style>
