export const Risk = {
  RAIN: {
    index: 1,
    key: "rain",
    string: "予測降雨",
    title: "予測降雨",
    icon: "mdi-weather-pouring",
    slider: true,
    subControl: true,
    isShowMobile: true
  },
  OBSERVATORY: {
    index: 2,
    key: "observatory",
    string: "観測所",
    title: "観測所",
    icon: "mdi-office-building-marker",
    subControl: true,
    isShowMobile: false
  },
  RIVER_FLOODING: {
    index: 3,
    key: "riverFlooding",
    string: "中小河川氾濫",
    title: "中小河川氾濫",
    icon: "mdi-waves",
    slider: true,
    subControl: true,
    isShowMobile: true
  },
  WIND: {
    index: 4,
    key: "wind",
    string: "風",
    title: "風",
    icon: "mdi-weather-windy",
    slider: true,
    subControl: true,
    isShowMobile: false
  },
  SNOW: {
    index: 5,
    key: "snow",
    string: "予測積雪深",
    title: "予測積雪深",
    icon: "mdi-weather-snowy-heavy",
    slider: true,
    subControl: true,
    isShowMobile: false
  },
  DOSYA: {
    index: 6,
    key: "dosya",
    string: "土砂キキクル",
    title: "土砂キキクル",
    icon: "mdi-elevation-decline",
    slider: true,
    subControl: true,
    isShowMobile: true
  },
  TYPHOON: {
    index: 7,
    key: "typhoon",
    string: "台風情報",
    title: "台風情報",
    icon: "mdi-weather-hurricane",
    slider: false,
    subControl: false,
    isShowMobile: true,
    url: "https://www.jma.go.jp/jp/typh/"
  },
  DISASTER_PREVENTION: {
    index: 8,
    key: "disasterPrevention",
    string: "川の防災情報",
    title: "川の防災情報",
    icon: "mdi-information",
    slider: false,
    subControl: false,
    isShowMobile: true,
    url: "https://www.river.go.jp/portal/?region=1301201&contents=multi"
  }
};
