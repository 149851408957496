const OBSERVATORY = {
  RAIN: {
    code: 1,
    name: "雨量"
  },
  STAGE: {
    code: 4,
    name: "水位"
  },
  DAM: {
    code: 7,
    name: "ダム"
  },
  CAMERA: {
    code: 30,
    name: "カメラ"
  },
  LIVECAMERA: {
    code: 50,
    name: "ｶﾒﾗ(動画)"
  }
};

const MapPane = {
  ObservatoryMarkersLayer: {
    name: "observatory-markers-layer",
    zIndex: 600
  },
  BranchOfficeMarker: {
    name: "branch-office-marker",
    zIndex: 610
  }
};

export { OBSERVATORY, MapPane };
