<template>
  <div class="pt-3 px-2">
    <div class="caption text--secondary">
      {{ observatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ observatory.lat }}, {{ observatory.lng }}
    </div>
    <div v-if="mesuredData.length === 0 && predictData.length === 0">
      この観測所は、休止もしくは欠測しています。
    </div>
    <ObservatoryStageRainChart
      v-if="
        rainData.length > 0 &&
          (mesuredData.length > 0 || predictData.length > 0)
      "
      :height="70"
      :selected-observatory="observatory"
      :chartTimeSeries="chartTimeSeries"
      :mesuredLatestValue="mesuredLatestValue"
    />
    <v-card height="10" tile flat></v-card>
    <observatory-stage-chart
      v-if="mesuredData.length > 0 || predictData.length > 0"
      :height="250"
      :selected-observatory="observatory"
      :chartTimeSeries="chartTimeSeries"
      :mesuredLatestValue="mesuredLatestValue"
      :displayHours="displayHours"
      :standardLevels="standardLevels"
    />
    <div class="text-right caption mt-2">
      {{ currentDateTime }}
    </div>
    <div class="mt-3">
      <div class="caption">基準水位</div>
      <v-row
        dense
        no-gutters
        class="body-2"
        v-for="std in standardLevels"
        :key="std.label"
      >
        <v-spacer />
        <v-col cols="1">
          <div
            class="standard-levels-color"
            :style="{ 'border-bottom-color': std.color }"
          ></div>
        </v-col>
        <v-col cols="6" class="caption">{{ std.label }}</v-col>
        <v-col cols="3" class="caption text-right">
          {{ std.value == undefined ? "---" : std.value.toFixed(2) + " m" }}
        </v-col>
        <v-spacer />
      </v-row>
    </div>
    <v-simple-table dense class="mt-3 timeseries">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left">水位 (m)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ts, index) in timeSeries"
            :key="index"
            :class="{ predict: ts.predict }"
          >
            <td>{{ ts.time }}</td>
            <td>{{ ts.level }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ObservatoryStageChart from "./StageChart";
import ObservatoryStageRainChart from "./StageRainChart";
import moment from "moment";
export default {
  name: "StageDetail",
  components: {
    ObservatoryStageChart,
    ObservatoryStageRainChart
  },
  data() {
    return {
      displayHours: 1
    };
  },
  computed: {
    isPublic() {
      return this.$store.getters.isPublic;
    },
    observatory() {
      return this.$store.getters.selectedObservatory;
    },
    currentDateTime() {
      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");
      return baseDate.local().format("YYYY/MM/DD HH:mm");
    },
    mesuredData() {
      return this.observatory.mesuredData === undefined
        ? []
        : this.observatory.mesuredData;
    },
    predictData() {
      if (this.isPublic === true) {
        return [];
      }
      return this.observatory.predictData === undefined
        ? []
        : this.observatory.predictData;
    },
    rainData() {
      return this.observatory.rainData === undefined
        ? []
        : this.observatory.rainData;
    },
    mesuredLatestValue() {
      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");
      return baseDate.format("YYYY/MM/DD HH:mm");
    },
    chartTimeSeries() {
      if (this.mesuredLatestValue === null) {
        return [];
      }
      const result = [];
      result.push(this.mesuredLatestValue);
      const latestMoment = moment.utc(
        this.mesuredLatestValue,
        "YYYY/MM/DD HH:mm"
      );
      [...Array(36).keys()].forEach(i => {
        const d = latestMoment.clone().add(-5 * (i + 1), "minutes");
        result.unshift(d.format("YYYY/MM/DD HH:mm"));
      });
      [...Array(this.isPublic === true ? 6 : 14).keys()].forEach(i => {
        const d = latestMoment.clone().add(5 * (i + 1), "minutes");
        result.push(d.format("YYYY/MM/DD HH:mm"));
      });
      return result;
    },
    timeSeries() {
      const timeSeries = [];
      this.chartTimeSeries.forEach(tsVal => {
        const finded =
          tsVal <= this.mesuredLatestValue
            ? this.mesuredData.find(m => m.date === tsVal)
            : this.predictData.find(p => p.date === tsVal);
        if (finded !== undefined) {
          timeSeries.push({
            time: moment
              .utc(tsVal, "YYYY/MM/DD HH:mm")
              .local()
              .format("MM/DD HH:mm"),
            level: parseFloat(finded.level).toFixed(2),
            predict: tsVal > this.mesuredLatestValue
          });
        }
      });
      return timeSeries;
    },
    standardLevels() {
      return [
        {
          color: "#008000",
          label: "水防団待機水位",
          value: this.observatory.standbyLevel
        },
        {
          color: "#ffa500",
          label: "氾濫注意水位",
          value: this.observatory.warningLevel
        },
        {
          color: "#ff2800",
          label: "避難判断水位",
          value: this.observatory.evacuationLevel
        },
        {
          color: "#ad02ad",
          label: "氾濫危険水位",
          value: this.observatory.dangerousLevel
        },
        {
          color: "#000000",
          label: "氾濫発生水位",
          value: this.observatory.outbreakLevel
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-levels-color {
  width: 16px;
  height: 12px;
  border-bottom: white 3px solid;
}
.timeseries {
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .std-color {
    width: 16px;
    height: 12px;
    border-bottom: white 3px solid;
  }
  .predict {
    color: blue;
  }
}
</style>
