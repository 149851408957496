import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { BaseTile } from "../enums/BaseTile";
import { Risk } from "../enums/Risk";
import * as axiosHelper from "../utils/axiosHelper";
import axios from "axios";
import moment from "moment";
import { OBSERVATORY } from "../enums/Type";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";

export default new Vuex.Store({
  state: {
    isPublic: process.env.VUE_APP_DOMAIN === "hachioji.riskma.jp",
    mobileHazardMap: {
      key: null,
      detail: []
    },
    isShowMobileHazardMap: false,
    user: {},
    baseDate: moment.utc(),
    selectedRisk: Risk.RAIN,
    selectedBaseTile: BaseTile.GRAY,
    selectedFeatures: [],
    selectedObservatory: {},
    selectedDate: moment.utc(),
    riskBaseDate: moment.utc(),
    leafletMap: null,
    isShowMapDetail: false,
    mapDetail: {
      title: "",
      contents: ""
    },
    observatories: [],
    isShowUsageGuide: false,
    imageSizeInfo: {},
    rainImageCoordinate: {},
    rainImageInfo: [],
    riskSubControl: {
      observatory: [
        OBSERVATORY.STAGE.code,
        OBSERVATORY.RAIN.code,
        OBSERVATORY.CAMERA.code,
        OBSERVATORY.LIVECAMERA.code
      ],
      opacity: 0.7
    },
    riverFloodingInfo: [],
    riverFloodingType: 3,
    dosyaInfo: {
      bounds: [],
      images: []
    },
    snowInfo: {
      bounds: [],
      images: []
    },
    usageGuide: {
      title: null,
      colors: []
    },
    hazardMapUsageGuide: {
      url: []
    },
    alerts: [],
    isNavigationVisible: false,
    isRightNavVisible: false,
    isMonitoring: true,
    isCameraMonitor: false,
    kouzuiInfo: [],
    isLoading: false,
    wind: {
      timeSeries: [],
      data: {}
    },
    selectedWind: {
      speed: 0,
      direction: 0
    },
    isMobileObservatoryRisk: true
  },
  getters: {
    isPublic: state => state.isPublic,
    mobileHazardMap: state => state.mobileHazardMap,
    baseDate: state => state.baseDate,
    selectedRisk: state => state.selectedRisk,
    selectedBaseTile: state => state.selectedBaseTile,
    selectedFeatures: state => state.selectedFeatures,
    selectedObservatory: state => state.selectedObservatory,
    selectedDate: state => state.selectedDate,
    riskBaseDate: state => state.riskBaseDate,
    imageSizeInfo: state => state.imageSizeInfo,
    rainImageInfo: state => state.rainImageInfo,
    rainImageCoordinate: state => state.rainImageCoordinate,
    leafletMap: state => state.leafletMap,
    isShowMapDetail: state => state.isShowMapDetail,
    mapDetail: state => state.mapDetail,
    observatories: state => state.observatories,
    riskSubControl: state => state.riskSubControl,
    isShowUsageGuide: state => state.isShowUsageGuide,
    riverFloodingInfo: state => state.riverFloodingInfo,
    riverFloodingType: state => state.riverFloodingType,
    dosyaInfo: state => state.dosyaInfo,
    snowInfo: state => state.snowInfo,
    usageGuide: state => state.usageGuide,
    hazardMapUsageGuide: state => state.hazardMapUsageGuide,
    alerts: state => state.alerts,
    isShowMobileHazardMap: state => state.isShowMobileHazardMap,
    isNavigationVisible: state => state.isNavigationVisible,
    isRightNavVisible: state => state.isRightNavVisible,
    isMonitoring: state => state.isMonitoring,
    isCameraMonitor: state => state.isCameraMonitor,
    kouzuiInfo: state => state.kouzuiInfo,
    isLoading: state => state.isLoading,
    wind: state => state.wind,
    selectedWind: state => state.selectedWind,
    isMobileObservatoryRisk: state => state.isMobileObservatoryRisk
  },
  mutations: {
    SET_MOBILE_HAZARD_MAP: function(state, payload) {
      state.mobileHazardMap = payload;
    },
    SET_BASE_DATE: function(state, payload) {
      state.baseDate = payload;
    },
    SET_SELECTED_RISK: function(state, payload) {
      state.selectedRisk = payload;
    },
    SET_SELECTED_FEATURES: function(state, payload) {
      state.selectedFeatures = payload;
    },
    SET_SELECTED_BASE_TILE: function(state, payload) {
      state.selectedBaseTile = payload;
    },
    SET_LEAFLET_MAP: function(state, payload) {
      state.leafletMap = payload;
    },
    SET_ALERTS: function(state, payload) {
      state.alerts = payload;
    },
    SET_OBSERVATORIES: function(state, payload) {
      state.observatories = payload;
    },
    SET_SELECTED_OBSERVATORY: function(state, payload) {
      state.selectedObservatory = payload;
    },
    SET_SELECTED_DATE: function(state, payload) {
      state.selectedDate = payload;
    },
    SET_RAIN_IMAGE_COORDINATE: function(state, payload) {
      state.rainImageCoordinate = payload;
    },
    SET_RAIN_IMAGE_INFO: function(state, payload) {
      state.rainImageInfo = payload;
    },
    SET_IMAGE_SIZE_INFO: function(state, payload) {
      state.imageSizeInfo = payload;
    },
    SET_RISK_BASE_DATE: function(state, payload) {
      state.riskBaseDate = payload;
    },
    SET_MAP_DETAIL: function(state, payload) {
      state.isShowMapDetail = payload.isShowing;
      state.mapDetail.title = payload.title;
      state.mapDetail.contents = payload.contents;
    },
    SET_RISK_SUB_CONTROL(state, payload) {
      const cloneRiskSubControl = Object.assign({}, state.riskSubControl);
      if (payload.observatory !== undefined) {
        cloneRiskSubControl.observatory = payload.observatory;
      }
      if (payload.opacity !== undefined) {
        cloneRiskSubControl.opacity = payload.opacity;
      }
      state.riskSubControl = cloneRiskSubControl;
    },
    ["SET_IS_SHOW_USAGE_GUIDE"](state, payload) {
      state.isShowUsageGuide = payload;
    },
    ["SET_SELECTED_AROUND_OBSERVATORIES"](state, payload) {
      state.selectedAroundObservatories = payload.observatories;
    },
    ["SET_RIVER_FLOODING_INFO"](state, payload) {
      state.riverFloodingInfo = payload;
    },
    SET_RIVER_FLOODING_TYPE: function(state, payload) {
      state.riverFloodingType = payload;
    },
    SET_DOSYA_INFO: function(state, payload) {
      state.dosyaInfo = payload;
    },
    SET_SNOW_INFO: function(state, payload) {
      state.snowInfo = payload;
    },
    SET_COGNITO_USER_INFO: function(state, payload) {
      state.user = payload;
    },
    SET_USAGE_GUIDE(state, payload) {
      state.usageGuide = payload;
    },
    SET_HAZARD_MAP_USAGE_GUIDE: function(state, payload) {
      state.hazardMapUsageGuide = payload;
    },
    SET_IS_SHOW_MOBILE_HAZARD_MAP: function(state, payload) {
      state.isShowMobileHazardMap = payload;
    },
    SET_IS_NAVIGATION: function(state, payload) {
      state.isNavigationVisible = payload;
    },
    SET_IS_RIGHT_NAV: function(state, payload) {
      state.isRightNavVisible = payload;
    },
    SET_IS_MONITORING: function(state, payload) {
      state.isMonitoring = payload;
    },
    SET_IS_CAMERA_MONITOR: function(state, payload) {
      state.isCameraMonitor = payload;
    },
    SET_KOUZUI_INFO: function(state, payload) {
      state.kouzuiInfo = payload;
    },
    SET_IS_LOADING: function(state, payload) {
      state.isLoading = payload;
    },
    SET_WIND_TIME_SERIES: function(state, payload) {
      state.wind.timeSeries = payload;
    },
    SET_WIND_DATA: function(state, payload) {
      state.wind.data = payload;
    },
    SET_SELECTED_WIND: function(state, payload) {
      state.selectedWind = payload;
    },
    SET_IS_MOBILE_OBSERVATORY_RISK: function(state, payload) {
      state.isMobileObservatoryRisk = payload;
    }
  },
  actions: {
    FETCH_OBSERVATORIES: async function(context) {
      const baseDate = this.state.baseDate.clone();
      const params = {
        date: baseDate.format(DATE_FORMAT)
      };
      const response = await axiosHelper.get("/api/observatories", params);
      if (response === null) {
        context.commit("SET_OBSERVATORIES", []);
        throw new Error("fetch failed : observatories");
      }

      context.commit("SET_OBSERVATORIES", response.observatories);
      // context.commit("SET_RISK_BASE_DATE", baseDate);
    },
    FETCH_SELECTED_OBSERVATORY_DATA: async function(context, target) {
      const observatory = Object.assign({}, target);
      const baseDate = this.state.baseDate.clone();

      if (observatory.type === OBSERVATORY.LIVECAMERA.code) {
        context.commit(
          "SET_SELECTED_OBSERVATORY",
          JSON.parse(JSON.stringify(target))
        );
        context.commit("SET_MAP_DETAIL", {
          isShowing: true,
          contents: "live-camera-detail",
          title: OBSERVATORY.LIVECAMERA.name
        });
        return;
      }

      const params = {
        date: baseDate.format(DATE_FORMAT),
        id: observatory._id,
        type: observatory.type
      };

      let selectedObservatory = {};

      if (observatory.isRiverApi === true) {
        const response = await axios.get(
          "https://data.riskma.net/bosai/observatories/data",
          {
            params
          }
        );
        selectedObservatory = JSON.parse(JSON.stringify(target));
        selectedObservatory.mesuredData = response.data.timeSeries.filter(
          ts => ts.type === "real"
        );
      } else {
        const response = await axiosHelper.get(
          "/api/observatories/data",
          params
        );
        if (response === null) {
          throw new Error("fetch failed : observatories/data");
        }
        if (response.observatoryData === undefined) {
          selectedObservatory = JSON.parse(JSON.stringify(target));
          selectedObservatory.mesuredData = [];
        } else {
          selectedObservatory = response.observatoryData[0];
        }
      }

      context.commit("SET_SELECTED_OBSERVATORY", selectedObservatory);

      if (this.state.isRightNavVisible === true) {
        context.commit("SET_IS_RIGHT_NAV", false);
      }

      let contents = "";
      let title = "観測所";
      switch (selectedObservatory.type) {
        case OBSERVATORY.RAIN.code:
          contents = "rain-detail";
          title = `雨量観測所 - ${selectedObservatory.name}`;
          break;
        case OBSERVATORY.STAGE.code:
          contents = "stage-detail";
          title = `水位観測所 - ${selectedObservatory.name}`;
          break;
        case OBSERVATORY.CAMERA.code:
          contents = "camera-detail";
          title = `カメラ - ${selectedObservatory.name}`;
          break;
      }

      context.commit("SET_MAP_DETAIL", {
        isShowing: true,
        title: title,
        contents: contents
      });
    },
    FETCH_RAIN: async function(context, { level, selectedDate = null }) {
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");

      const params = {
        date: baseDate.format(DATE_FORMAT),
        level: level
      };
      const response = await axiosHelper.get("/api/rain", params);

      if (response === null) {
        throw new Error("fetch failed : rainfall");
      }
      const rains = response.imageInfos;

      const loadImage = src => {
        return new Promise(resolve => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = () => resolve(null);
          image.src = src;
        });
      };

      let rainImageInfos = rains.map(async info => {
        const image = await loadImage(info.url);
        return {
          date: info.date,
          type: info.type,
          image: image
        };
      });

      let res = await Promise.all(rainImageInfos);
      res = res.filter(row => row.image !== null);
      context.commit("SET_RAIN_IMAGE_INFO", res);

      context.commit(
        "SET_SELECTED_DATE",
        selectedDate || moment.utc(response.baseDate, DATE_FORMAT)
      );

      context.commit("SET_RAIN_IMAGE_COORDINATE", response.bounds);

      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );

      context.commit("SET_IMAGE_SIZE_INFO", response.size);
    },
    FETCH_RIVER_FLOODING: async function(context) {
      const baseDate = this.state.baseDate.clone();

      const parameters = {
        type: this.state.riverFloodingType,
        date: baseDate.utc().format(DATE_FORMAT)
      };

      const response = await axiosHelper.get("/api/river-floods", parameters);
      if (response === null) {
        throw new Error("fetch failed : river-floods");
      }

      const loadImage = src => {
        return new Promise(resolve => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = () => resolve(null);
          image.src = src;
        });
      };

      const imageInfos = response.imageInfos.map(async info => {
        const image = await loadImage(info.url);
        return {
          date: info.date,
          image: image,
          url: info.url,
          type: "FORECAST"
        };
      });

      let res = await Promise.all(imageInfos);
      res = res.filter(row => row.image !== null);
      context.commit("SET_RIVER_FLOODING_INFO", {
        bounds: response.bounds,
        images: res
      });

      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );

      context.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
    },
    FETCH_DOSYA: async function(context) {
      let baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");

      const params = {
        date: baseDate.utc().format(DATE_FORMAT)
      };
      context.commit("SET_DOSYA_INFO", {});

      axios
        .create({
          responseType: "json"
        })
        .get("https://data.riskma.net/bosai/dosya", { params })
        .then(async response => {
          const res = response.data;
          const dosyaInfo = {
            bounds: [],
            images: []
          };
          if (!res.imageInfos) {
            context.commit("SET_DOSYA_INFO", dosyaInfo);
            return;
          }

          dosyaInfo.bounds = res.bounds;

          const loadImage = src => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.onload = () => resolve(image);
              image.onerror = e => reject(e);
              image.src = src;
            });
          };

          const infos = res.imageInfos.map(async info => {
            const image = await loadImage(info.url);
            return {
              date: info.date,
              image: image,
              url: info.url
            };
          });
          const infosRes = await Promise.all(infos);
          dosyaInfo.images = infosRes.filter(row => row.image !== null);
          context.commit("SET_DOSYA_INFO", dosyaInfo);

          context.commit(
            "SET_RISK_BASE_DATE",
            moment.utc(res.baseDate, DATE_FORMAT)
          );

          baseDate = res.baseDate
            ? moment.utc(res.baseDate, DATE_FORMAT)
            : baseDate;
          context.commit("SET_SELECTED_DATE", baseDate);
        })
        .catch(err => {
          console.log(err);
        });
    },
    FETCH_ALERT: async function(context) {
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");

      const params = {
        date: baseDate.format(DATE_FORMAT)
      };
      const response = await axiosHelper.get("/api/alerts", params);
      if (response === null) {
        throw new Error("fetch failed: alerts");
      }

      const alerts = response.alerts.map(item => {
        const date = moment
          .utc(item._id, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("YYYY/MM/DD HH:mm");
        const row = {
          date: date,
          color: "",
          title: item.title,
          text: item.text,
          comment: "",
          presentationName: ""
        };
        if (item.level === "気象情報" && item.type === "no_status") {
          row.color = "#666666";
          row.presentationName = "気象情報";
          row.comment = item.comment;
        } else if (item.level === "記録的短時間大雨情報") {
          row.color = "#666666";
          row.presentationName = "記録的短時間大雨情報";
        } else {
          row.title = item.alert_names.join(", ");
          row.area = item.area_names.join(", ");
          if (item.category === "release") {
            row.color = "green";
            row.presentationName = "解除";
          } else {
            const levels = item.alert_levels;
            if (levels.includes(3)) {
              row.color = "purple";
              row.presentationName = "特別警報";
            } else if (levels.includes(1)) {
              row.color = "red";
              row.presentationName = "警報";
            } else if (levels.includes(2)) {
              row.color = "warning";
              row.presentationName = "注意報";
            }
          }
        }
        return row;
      });

      context.commit("SET_ALERTS", alerts);
    },
    FETCH_WIND_TIME_SERIES: async function(context) {
      const baseDate = this.state.baseDate.clone();
      const params = {
        date: baseDate.format("YYYY/MM/DD HH:mm")
      };

      // RisKma駿河API
      const response = await axiosHelper.getOutsideApi(
        "https://74fhcop6nh.execute-api.ap-northeast-1.amazonaws.com/prod/api/wind/timeseries",
        params
      );
      if (response === null) {
        throw new Error("fetch failed: wind/timeseries");
      }

      const timeSeries = [];
      response.timeSeries.map(row => {
        timeSeries.push({
          date: row
        });
      });
      context.commit("SET_RISK_BASE_DATE", moment.utc(response.baseDate));
      context.commit("SET_WIND_TIME_SERIES", timeSeries);
      context.commit("SET_SELECTED_DATE", moment.utc(response.baseDate));
    },
    FETCH_WIND_DATA: async function(context) {
      const riskBaseDate = this.state.riskBaseDate.clone();
      const selectedDate = this.state.selectedDate.clone();

      const params = {
        baseDate: riskBaseDate.utc().format("YYYY/MM/DD HH:mm"),
        date: selectedDate.utc().format("YYYY/MM/DD HH:mm")
      };

      // RisKma駿河API
      const response = await axiosHelper.getOutsideApi(
        "https://74fhcop6nh.execute-api.ap-northeast-1.amazonaws.com/prod/api/wind",
        params
      );
      if (response === null) {
        throw new Error("fetch failed: wind");
      }

      context.commit("SET_WIND_DATA", response.data);
    }
  }
});
