<script>
import { Line } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
Chart.plugins.register(ChartAnnotationsPlugin);
import moment from "moment";
import _ from "lodash";
export default {
  extends: Line,
  name: "StageRainChart",
  props: {
    selectedObservatory: {
      type: Object
    },
    chartTimeSeries: {
      type: Array
    },
    mesuredLatestValue: String
  },
  data() {
    return {
      rains: [],
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "10分雨量",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm",
            pointBackgroundColor: "white",
            borderWidth: 3,
            backgroundColor: "#1C344C",
            tooltipLabelColor: "#1C344C",
            borderColor: "#1C344C",
            pointRadius: 0,
            lineTension: 0,
            pointHitRadius: 0,
            fill: true,
            data: [],
            spanGaps: true
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0,
            left: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "rain",
              gridLines: {
                display: true
              },
              ticks: {
                reverse: true,
                max: 5,
                min: 0,
                stepSize: 1,
                beginAtZero: true,
                callback: tick => {
                  return ("        " + parseFloat(tick).toFixed(1)).slice(-6);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              gridLines: {
                display: false
              },
              ticks: {
                display: false,
                callback: tick => {
                  const hm = tick.substr(6, 5);
                  const m = Number(hm.substr(3, 2));
                  return m === 0 ? hm : null;
                }
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "06:10",
              borderColor: "#FF0000",
              borderWidth: 1.5
            }
          ]
        },
        legend: {
          display: false
        }
      }
    };
  },
  watch: {
    selectedObservatory() {
      this.render();
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      setTimeout(() => {
        this.renderChart(this.dataCollection, this.options);
      }, 500);
    },
    setTimeSeries() {
      this.dataCollection.datasets[0].data = [];
      this.rains = [];
      this.dataCollection.labels = this.chartTimeSeries.map(ts => {
        return moment
          .utc(ts, "YYYY/MM/DD HH:mm")
          .local()
          .format("MM/DD HH:mm");
      });
      if (this.selectedObservatory.rainData.length === 0) {
        return;
      }
      const rainData = this.selectedObservatory.rainData;
      this.chartTimeSeries.forEach(ts => {
        if (
          this.$store.getters.isPublic === true &&
          ts > this.mesuredLatestValue
        ) {
          this.rains.push(null);
        } else {
          const finded = rainData.find(r => r.date === ts);
          this.rains.push(
            finded === undefined ? null : parseFloat(finded.value.toFixed(2))
          );
        }
      });
      this.dataCollection.datasets[0].data = Object.assign(this.rains);
      this.options.annotation.annotations[0].value = moment
        .utc(this.mesuredLatestValue, "YYYY/MM/DD HH:mm")
        .local()
        .format("MM/DD HH:mm");
    },
    setYAxesLimitData() {
      const maxLevel = _.max(this.rains.filter(r => r !== null));
      if (maxLevel > 5) {
        this.options.scales.yAxes[0].ticks.max = Math.floor(
          parseFloat(maxLevel) + 3
        );
      }
    }
  }
};
</script>

<style scoped></style>
