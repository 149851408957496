const BRANCH_OFFICE = [
  {
    name: "八王子市役所",
    postal: "192-8501",
    address: "東京都八王子市元本郷町3丁目24-1",
    lat: 35.666431,
    lng: 139.31584
  }
];

export { BRANCH_OFFICE };
