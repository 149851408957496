export const Feature = [
  {
    key: "shinsuishin",
    label: "洪水浸水想定区域\n(想定最大規模)",
    urls: [
      "https://s3.ap-northeast-1.amazonaws.com/ctie.hachioji.web/static/tile/sinsou/{z}/{x}/{y}.png"
    ],
    usageGuide: [
      {
        title: "洪水浸水想定区域\n想定浸水深（ｍ）",
        colors: [
          { color: "rgb(220, 122, 220)", value: "20.0~" },
          { color: "rgb(242, 133, 201)", value: "10.0~20.0" },
          { color: "rgb(255, 145, 145)", value: "5.0~10.0" },
          { color: "rgb(255, 183, 183)", value: "3.0~5.0" },
          { color: "rgb(255, 216, 192)", value: "0.5~3.0" },
          { color: "rgb(247, 245, 169)", value: "~0.5" }
        ]
      }
    ],
    isShow: true
  },
  {
    key: "keizoku",
    label: "浸水継続時間\n(想定最大規模)",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_keizoku_data/{z}/{x}/{y}.png"
    ],
    usageGuide: [
      {
        title: "浸水継続時間",
        colors: [
          { color: "rgb(160, 210, 255)", value: "12時間未満" },
          { color: "rgb(0, 65, 255)", value: "12時間〜1日未満" },
          { color: "rgb(250, 245, 0)", value: "1日〜3日未満" },
          { color: "rgb(255, 153, 0)", value: "3日〜1週間未満" },
          { color: "rgb(255, 40, 0)", value: "1週間〜2週間未満" },
          { color: "rgb(180, 0, 104)", value: "2週間〜4週間未満" },
          { color: "rgb(96, 0, 96)", value: "4週間以上〜" }
        ]
      }
    ],
    isShow: true
  },
  {
    key: "kaokutoukai_hanran",
    label: "家屋倒壊等氾濫想定区域\n(氾濫流)",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_hanran_data/{z}/{x}/{y}.png"
    ],
    usageGuide: [
      {
        title: "家屋倒壊等氾濫想定区域\n(氾濫流)",
        image:
          "https://disaportal.gsi.go.jp/hazardmap/copyright/img/kaokutoukai_hanran.png"
      }
    ],
    isShow: true
  },
  {
    key: "kaokutoukai_kagan",
    label: "家屋倒壊等氾濫想定区域\n(河岸侵食)",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_kagan_data/{z}/{x}/{y}.png"
    ],
    usageGuide: [
      {
        title: "家屋倒壊等氾濫想定区域\n(河岸侵食)",
        image:
          "https://disaportal.gsi.go.jp/hazardmap/copyright/img/kaokutoukai_kagan.png"
      }
    ],
    isShow: true
  },
  {
    key: "dosyasaigai_keikaikuiki",
    label: "土砂災害警戒区域",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png",
      "https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png",
      "https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png"
    ],
    usageGuide: [
      {
        title: "特別警戒区域",
        colors: [
          { color: "rgb(193, 76, 98)", value: "土石流" },
          { color: "rgb(252, 104, 77)", value: "急傾斜地の崩壊" },
          { color: "rgb(203, 76, 147)", value: "地滑り" }
        ]
      },
      {
        title: "警戒区域",
        colors: [
          { color: "rgb(238, 216, 118)", value: "土石流" },
          { color: "rgb(255, 237, 90)", value: "急傾斜地の崩壊" },
          { color: "rgb(255, 183, 84)", value: "地滑り" }
        ]
      }
    ],
    isShow: true
  }
];
