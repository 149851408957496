<template>
  <div class="expansion-panels">
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
          拠点
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(branch, idx) in branchOffices">
            <v-btn
              :key="idx"
              x-small
              block
              class="mb-1"
              elevation="0"
              color="#616161"
              outlined
              @click="panTo(branch)"
            >
              {{ branch.name }}
            </v-btn>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { BRANCH_OFFICE } from "../../../enums/BranchOffice";
export default {
  name: "BranchOfficeControl",
  components: {},
  data() {
    return {
      branchOffices: BRANCH_OFFICE
    };
  },
  methods: {
    panTo(branch) {
      const map = this.$store.getters.leafletMap;
      if (!map) {
        return;
      }
      map.panTo([branch.lat, branch.lng]);
      if (map.getZoom() < 14) {
        setTimeout(() => {
          map.setZoom(14);
        }, 800);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.base-tile-control {
  width: 140px;
}
</style>
