<template>
  <v-navigation-drawer v-model="drawer" :mini-variant="mini" permanent app>
    <v-list dense>
      <v-list-item link @click.stop="mini = !mini">
        <v-list-item-icon>
          <v-icon v-if="mini">mdi-menu</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>メニュー</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <div v-for="(item, itemIndex) in items" :key="itemIndex">
        <template v-for="si in item.subitems">
          <v-list-item
            link
            @click="si.onclick"
            :key="si.key"
            :input-value="selectedRisk.title === si.title"
            v-if="isSubitemVisible(si.key)"
          >
            <v-list-item-icon>
              <v-tooltip right z-index="3000">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">{{ si.icon }}</v-icon>
                </template>
                <span>{{ si.title }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ si.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider />
      </div>
    </v-list>
    <v-dialog v-model="usingDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-4">
          利用における注意事項
        </v-card-title>
        <v-card-text class="pt-3">
          &emsp;本システムで提供する観測情報は、無人観測所から送られてくるデータをただちにお知らせする目的で作られています。
          そのため、観測機器の故障や通信異常等による異常データがそのまま表示されてしまう可能性がありますのでご注意ください。
        </v-card-text>
        <v-card-text>
          &emsp;本情報の利用により発生した損害について、市では一切の責任を負いかねますので、ご了承ください。
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="usingDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { Risk } from "../enums/Risk";
import { BRANCH_OFFICE } from "../enums/BranchOffice";
export default {
  name: "NavigationDrawer",
  data() {
    return {
      drawer: true,
      mini: true,
      usingDialog: false,
      items: [
        {
          subitems: [
            {
              key: "home",
              icon: "mdi-home-map-marker",
              title: "ホーム",
              onclick: () => {
                this.$store.commit("SET_IS_CAMERA_MONITOR", false);
                this.$store.commit("SET_MAP_DETAIL", { isShowing: false });
                this.$store.commit("SET_IS_RIGHT_NAV", true);
                this.$store.commit("SET_IS_MONITORING", true);
                if (this.map) {
                  this.map.panTo([BRANCH_OFFICE[0].lat, BRANCH_OFFICE[0].lng]);
                  setTimeout(() => {
                    this.map.setZoom(14);
                  }, 800);
                }
              }
            },
            {
              key: Risk.RAIN.key,
              icon: Risk.RAIN.icon,
              title: Risk.RAIN.title,
              onclick: () => this.$store.commit("SET_SELECTED_RISK", Risk.RAIN)
            },
            {
              key: Risk.RIVER_FLOODING.key,
              icon: Risk.RIVER_FLOODING.icon,
              title: Risk.RIVER_FLOODING.title,
              onclick: () =>
                this.$store.commit("SET_SELECTED_RISK", Risk.RIVER_FLOODING)
            },
            {
              key: Risk.DOSYA.key,
              icon: Risk.DOSYA.icon,
              title: Risk.DOSYA.title,
              onclick: () => this.$store.commit("SET_SELECTED_RISK", Risk.DOSYA)
            }
          ]
        },
        {
          subitems: [
            {
              icon: Risk.TYPHOON.icon,
              title: Risk.TYPHOON.title,
              onclick: () => window.open(Risk.TYPHOON.url)
            },
            {
              icon: Risk.DISASTER_PREVENTION.icon,
              title: Risk.DISASTER_PREVENTION.title,
              onclick: () => window.open(Risk.DISASTER_PREVENTION.url)
            },
            {
              icon: "mdi-book-open-variant",
              title: "マニュアル",
              onclick: () =>
                window.open("https://hachioji.riskma.jp/manual.pdf")
            }
          ]
        },
        {
          subitems: [
            {
              icon: "mdi-monitor-eye",
              title: "水位・雨量モニタ",
              onclick: () => {
                const bool = !this.$store.getters.isMonitoring;
                this.$store.commit("SET_IS_MONITORING", bool);
                if (bool === true) {
                  this.$store.commit("SET_IS_CAMERA_MONITOR", false);
                }
              }
            },
            {
              icon: "mdi-monitor-dashboard",
              title: "カメラ一覧",
              onclick: () => {
                const bool = !this.$store.getters.isCameraMonitor;
                this.$store.commit("SET_IS_CAMERA_MONITOR", bool);
                this.$store.commit("SET_IS_MONITORING", !bool);
                this.$store.commit("SET_IS_RIGHT_NAV", !bool);
                this.$store.commit("SET_MAP_DETAIL", { isShowing: false });
              }
            },
            {
              icon: "mdi-message-bulleted",
              title: "お知らせ",
              onclick: () => {
                if (this.$store.getters.isShowMapDetail === true) {
                  this.$store.commit("SET_MAP_DETAIL", { isShowing: false });
                  this.$store.commit("SET_IS_RIGHT_NAV", true);
                } else {
                  const bool = !this.$store.getters.isRightNavVisible;
                  this.$store.commit("SET_IS_RIGHT_NAV", bool);
                  if (bool === true) {
                    this.$store.commit("SET_IS_CAMERA_MONITOR", false);
                  }
                }
              }
            }
          ]
        },
        {
          subitems: [
            {
              icon: "mdi-alert-outline",
              title: "ご利用に際して",
              onclick: () => {
                this.usingDialog = true;
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    isPublic() {
      return this.$store.getters.isPublic;
    }
  },
  methods: {
    isSubitemVisible(key) {
      const isPublic = this.$store.getters.isPublic;
      if (isPublic === true && key === Risk.RIVER_FLOODING.key) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
