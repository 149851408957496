<template>
  <div class="hazard-map-control-container">
    <div :class="isShowMobileHazardMap ? 'fade-in' : 'fade-out'">
      <v-card style="pointer-events: none" color="rgba(255, 255, 255, 0.9)">
        <v-switch
          class="ma-1 pa-1"
          style="pointer-events: auto"
          v-model="feature.isShow"
        >
          <template v-slot:label>
            <div style="display: inline-block">
              <div
                v-if="feature.usageGuide === undefined"
                style="font-weight: bold; white-space: pre-wrap;"
              >
                {{ feature.label }}
              </div>
              <div v-else>
                <template v-for="(usage, usageIndex) in feature.usageGuide">
                  <div :key="`${usageIndex}`">
                    <div v-if="usage.title" class="usage-title">
                      {{ usage.title }}
                    </div>
                    <template v-if="usage.colors">
                      <template v-for="(cl, clIndex) in usage.colors">
                        <div :key="`${usageIndex}-${clIndex}`">
                          <div
                            :style="
                              `background-color: ${cl.color}; width: 20px; height:10px; display: inline-block`
                            "
                          />
                          <span style="padding-left: 5px">{{ cl.value }}</span>
                        </div>
                      </template>
                    </template>
                    <template v-if="usage.image">
                      <img
                        :src="usage.image"
                        height="30"
                        width="40"
                        alt="usageGuide"
                      />
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </v-switch>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHazardMapControl",
  data() {
    return {
      isOpen: true
    };
  },
  computed: {
    isShowMobileHazardMap() {
      return this.$store.getters.isShowMobileHazardMap;
    },
    feature() {
      return this.$store.getters.mobileHazardMap.detail;
    }
  },
  methods: {
    callback(selectedItem) {
      this.$store.commit("SET_MOBILE_HAZARD_MAP", {
        key: selectedItem.value.key,
        detail: selectedItem.value.categories
      });
    }
  }
};
</script>

<style lang="scss">
.hazard-map-control-container {
  height: 450px;
  width: 200px;
  pointer-events: none;

  .usage-title {
    white-space: pre;
    line-height: 16px;
  }

  .disp-btn {
    pointer-events: auto;
  }

  .fade-in {
    animation-name: fadein;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .fade-out {
    animation-name: fadeout;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    pointer-events: none;
    visibility: hidden;
  }
  @keyframes fadein {
    0% {
      transform: translateX(-350px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes fadeout {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-300px);
    }
  }

  .v-label {
    font-size: 8px !important;
  }

  .v-messages {
    display: none;
  }
}
</style>
