<template>
  <div>
    <div v-if="hazardMapUsageGuide.length !== 0">
      <div v-for="(usageItem, index) in hazardMapUsageGuide" :key="index">
        <template v-if="Array.isArray(usageItem)">
          <div
            v-for="(ui, uiIndex) in usageItem"
            :key="`${index}_${uiIndex}`"
            class="legend-area"
          >
            <div class="legend-title" v-if="ui.title && ui.colors">
              {{ ui.title }}
            </div>
            <v-card class="legend" tile elevation="5" v-if="ui.colors">
              <div
                v-for="(o, index) in ui.colors"
                :key="index"
                class="val"
                :style="{ backgroundColor: o.color }"
              >
                {{ o.value }}
              </div>
            </v-card>
          </div>
        </template>
        <template v-else>
          <div class="legend-area" v-if="usageItem.colors">
            <div class="legend-title" v-if="usageItem.title">
              {{ usageItem.title }}
            </div>
            <v-card class="legend" tile elevation="5">
              <div
                v-for="(o, index) in usageItem.colors"
                :key="index"
                class="val"
                :style="{ backgroundColor: o.color }"
              >
                {{ o.value }}
              </div>
            </v-card>
          </div>
        </template>
      </div>
    </div>
    <div v-if="usageGuide">
      <div
        v-for="(usageItem, index) in usageGuide"
        :key="index"
        class="legend-area"
      >
        <div class="legend-title" v-if="usageItem.title">
          {{ usageItem.title }}
        </div>
        <v-card class="legend" tile elevation="5">
          <div
            v-for="(o, index) in usageItem.colors"
            :key="index"
            class="val"
            :style="{ backgroundColor: o.color }"
          >
            {{ o.value }}
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageGuideLayer",
  computed: {
    usageGuide() {
      const guides = [];
      const tmp = this.$store.getters.usageGuide;
      if (Array.isArray(tmp)) {
        tmp.forEach(t => {
          guides.push(t);
        });
      } else {
        guides.push(tmp);
      }
      return guides;
    },
    hazardMapUsageGuide() {
      return Object.assign({}, this.$store.getters.hazardMapUsageGuide);
    }
  }
};
</script>

<style scoped lang="scss">
.hazard-usage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.legend-area {
  clear: both;
  .legend-title {
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    white-space: pre-wrap;
    text-shadow: 1px 1px 0 #ffffff, -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
      -1px -1px 0 #ffffff;
  }
  .legend {
    float: right;
    margin-bottom: 10px;
    .val {
      color: #ffffff;
      text-shadow: 1px 1px 0 #2c2c2c, -1px 1px 0 #2c2c2c, 1px -1px 0 #2c2c2c,
        -1px -1px 0 #2c2c2c;
      font-weight: bold;
      font-size: 12px;
      min-width: 12px;
      text-align: center;
      line-height: 1;
      padding: 0 10px;
      line-height: 20px;
      height: 20px;
      opacity: 0.9;
    }
  }
}
</style>
