<template>
  <div class="camera-monitor">
    <v-card
      class="caption pt-3 pl-3 camera-monitor-header"
      height="60"
      flat
      color="#F5F5F5"
    >
      <v-select
        v-model="selectedCameraId"
        :items="cameras"
        item-text="name"
        item-value="_id"
        label="カメラ選択"
        dense
        outlined
        hide-details
        class="caption"
        style="width: 240px"
        @change="select()"
      />
    </v-card>
    <v-card class="d-flex flex-wrap" tile flat color="#F5F5F5">
      <div
        v-for="(station, index) in stations"
        :key="station._id"
        :id="`row-${station._id}`"
      >
        <v-card
          class="pa-2"
          style="margin-top: 60px"
          :width="cardWidth"
          tile
          flat
          color="#F5F5F5"
        >
          <v-card
            style="padding: 8px 14px; cursor: pointer;"
            flat
            tile
            outlined
            @click="moveStation(station)"
          >
            <v-card height="50" flat tile>
              <div class="body-2">{{ index + 1 + ". " + station.name }}</div>
              <div class="caption" v-if="station.address">
                {{ station.address }}
              </div>
            </v-card>
            <v-img
              v-if="station.url"
              :width="cardWidth - 30"
              :height="(cardWidth - 30) / 2"
              :src="station.url"
            />
            <v-img
              v-else
              :width="cardWidth - 30"
              :height="(cardWidth - 30) / 2"
              :src="require('@/assets/images/camera-none.jpg')"
              contain
            />
            <div class="caption">{{ station.date }}</div>
          </v-card>
        </v-card>
      </div>
    </v-card>
  </div>
</template>
<script>
import * as axiosHelper from "../utils/axiosHelper";
import moment from "moment";
export default {
  name: "MobileCameraMonitor",
  components: {},
  data() {
    return {
      windowWidth: window.innerWidth,
      stations: [],
      imageWindow: null,
      selectedCameraId: null
    };
  },

  computed: {
    cameras() {
      const observatories = this.$store.getters.observatories;
      const cameras = observatories.filter(obs => obs.type === 30);
      cameras.sort((a, b) => {
        // 緯度降順
        if (a.lat > b.lat) return -1;
        if (a.lat < b.lat) return 1;
        return 0;
      });
      return cameras;
    },
    rowPanelNum() {
      const num = Math.floor(this.windowWidth / 650);
      if (num < 1) {
        return 1;
      } else {
        return num + 1;
      }
    },
    cardWidth() {
      return Math.floor(this.windowWidth / this.rowPanelNum);
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },

  watch: {
    baseDate(newval, oldval) {
      if (!newval.isSame(oldval)) {
        this.sync();
      }
    }
  },

  async created() {
    await this.$store.dispatch("FETCH_OBSERVATORIES");
    window.addEventListener("resize", this.resizeWindow);
    this.sync();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
  },

  mounted() {
    //
  },

  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    close() {
      this.$store.commit("SET_IS_CAMERA_MONITOR", false);
    },
    select() {
      this.$el.querySelector(`#row-${this.selectedCameraId}`).scrollIntoView();
    },
    moveStation(station) {
      const url = `image.html?url=${station.url}`;
      if (this.imageWindow === null || this.imageWindow.closed === true) {
        this.imageWindow = window.open(url);
      } else {
        this.imageWindow.location.href = url;
        setTimeout(() => {
          this.imageWindow.focus();
        }, 500);
      }
    },
    async sync() {
      this.stations = [];
      const processes = this.cameras.map(target => {
        return (async () => {
          const params = {
            date: this.baseDate.format("YYYY/MM/DD HH:mm"),
            id: target._id,
            type: 30
          };
          const row = { ...target };
          row.date = "";
          row.url = "";
          this.stations.push(row);
          const response = await axiosHelper.get(
            "/api/observatories/data",
            params
          );
          if (response !== null && response.observatoryData) {
            const cameraData = response.observatoryData[0].cameraData;
            if (cameraData && cameraData.length > 0) {
              const latest = cameraData[cameraData.length - 1];
              row.date = moment
                .utc(latest.date)
                .local()
                .format("YYYY年M月D日 HH時mm分");
              row.url = latest.url;
            }
          }
        })();
      });
      await Promise.all(processes);
    }
  }
};
</script>

<style lang="scss" scoped>
.camera-monitor {
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  height: 100dvh;
}
.camera-monitor-header {
  background-color: #f5f5f5;
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
</style>
