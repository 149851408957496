import axios from "axios";
import * as cognito from "../utils/cognito";
import { apiBaseUrl } from "../config";

export const get = async (url, params) => {
  const baseUrl = apiBaseUrl[process.env.VUE_APP_DOMAIN][process.env.NODE_ENV];
  const headers = {};
  if (process.env.VUE_APP_DOMAIN === "hachioji-shokuin.riskma.jp") {
    const auth = await cognito.isAuthenticated();
    const token = auth.idToken.jwtToken;
    headers.Authorization = token;
  }

  return axios
    .create({
      responseType: "json",
      baseURL: baseUrl,
      headers: headers
    })
    .get(url, {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
};

export const getOutsideApi = async (url, params) => {
  return axios
    .create({
      responseType: "json"
    })
    .get(url, {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
};
