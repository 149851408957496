<template>
  <div class="layer-control">
    <custom-expansion-panel
      :data="layerGroups"
      :multiple-select="true"
      @change="callback"
    ></custom-expansion-panel>
  </div>
</template>

<script>
import CustomExpansionPanel from "./CustomExpansionPanel";
import { Feature } from "../../../enums/Feature";

export default {
  name: "TerrainFeaturesControlLayer",
  components: { CustomExpansionPanel },
  data() {
    return {
      layerGroups: []
    };
  },
  created() {
    const items = Feature.map(feature => {
      return {
        label: feature.label,
        value: feature
      };
    });
    this.layerGroups.push({
      label: "ハザードマップ",
      selectedItems: [],
      items: items
    });
  },
  methods: {
    callback(selectedItems) {
      const selected = selectedItems.map(item => item.value);
      this.$store.commit("SET_SELECTED_FEATURES", selected);
      let hazardMapUsage = [];
      selectedItems.forEach(item => {
        const target = JSON.stringify(item.value.usageGuide);
        const finded = hazardMapUsage.find(h => JSON.stringify(h) === target);
        if (finded === undefined && item.value.usageGuide) {
          hazardMapUsage.push(item.value.usageGuide);
        }
      });
      this.$store.commit("SET_HAZARD_MAP_USAGE_GUIDE", hazardMapUsage);
    }
  }
};
</script>

<style lang="scss" scoped>
.layer-control {
  width: 140px;
}
</style>
