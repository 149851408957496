<template>
  <SlicedImageLayer :bounds="bounds" :images="images" v-if="images" />
</template>
<script>
import SlicedImageLayer from "./SlicedImageLayer";
export default {
  name: "RiverFloodingLayer",
  components: { SlicedImageLayer },
  computed: {
    bounds() {
      return this.$store.getters.riverFloodingInfo.bounds;
    },
    images() {
      return this.$store.getters.riverFloodingInfo.images;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_RIVER_FLOODING");
    }
  },
  mounted() {
    this.$store.commit("SET_USAGE_GUIDE", [
      {
        title: "内水リスク",
        colors: [
          { color: "#ffc747", value: "3" },
          { color: "#ffc796", value: "2" },
          { color: "#ffecda", value: "1" }
        ]
      },
      {
        title: "外水リスク(m)",
        colors: [
          { color: "#003671", value: "1.5" },
          { color: "#228ff8", value: "1.0" },
          { color: "#8dc5fa", value: "0.5" },
          { color: "#d8ecfd", value: "0.01" }
        ]
      }
    ]);
  }
};
</script>
