<template>
  <div>
    <template v-for="(branch, idx) in branchOffices">
      <LMarker :key="idx" :lat-lng="[branch.lat, branch.lng]" :pane="paneName">
        <LIcon
          :icon-size="[36, 36]"
          :icon-anchor="[18, 18]"
          :icon-url="iconUrl"
        />
        <LTooltip
          :content="`<b>${branch.name}</b><br>${branch.address}`"
          :options="{ permanent: false, offset: [0, -18], direction: 'top' }"
        />
      </LMarker>
    </template>
  </div>
</template>

<script>
import { BRANCH_OFFICE } from "../../../enums/BranchOffice";
import { MapPane } from "../../../enums/Type";
import { LMarker, LTooltip, LIcon } from "vue2-leaflet";
export default {
  name: "BranchOfficeControl",
  components: {
    LMarker,
    LTooltip,
    LIcon
  },
  data() {
    return {
      branchOffices: BRANCH_OFFICE,
      paneName: MapPane.BranchOfficeMarker.name,
      iconUrl: require(`@/assets/images/png/domain.png`)
    };
  },
  created() {
    //
  }
};
</script>
