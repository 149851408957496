<template>
  <l-tile-layer
    v-if="selectedBaseTile"
    :url="selectedBaseTile.url"
    :options="{ maxNativeZoom: maxNativeZoom, updateWhenIdle: false }"
    :attribution="attribution"
  />
</template>

<script>
import { LTileLayer } from "vue2-leaflet";
import { BaseTile } from "../../../enums/BaseTile";

export default {
  name: "BaseTileLayer",
  components: {
    LTileLayer
  },
  data() {
    return {
      BaseTile,
      attribution:
        "<a href='https://maps.gsi.go.jp/development/ichiran.html'>地理院タイル</a>"
    };
  },
  computed: {
    selectedBaseTile() {
      return this.$store.getters.selectedBaseTile;
    },
    maxNativeZoom() {
      switch (this.selectedBaseTile) {
        case BaseTile.WHITE:
          return 14;
        default:
          return 18;
      }
    }
  }
};
</script>
