<template>
  <v-app id="app">
    <navigation-drawer v-if="isNavigationVisible" />
    <v-main>
      <router-view />
    </v-main>
    <right-side-contents v-if="isSidebarVisible" />
  </v-app>
</template>

<script>
import NavigationDrawer from "./components/NavigationDrawer";
import RightSideContents from "./components/RightSideContents";
export default {
  name: "app",
  components: {
    NavigationDrawer,
    RightSideContents
  },
  data() {
    return {};
  },
  computed: {
    isNavigationVisible() {
      return (
        this.$store.getters.isNavigationVisible === true &&
        this.$route.path === "/"
      );
    },
    isSidebarVisible() {
      return (
        this.$store.getters.isRightNavVisible === true &&
        this.$route.path === "/"
      );
    },
    isMobile() {
      return this.$route.path === "/mobile";
    }
  },
  methods: {
    //
  }
};
</script>

<style lang="scss">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  .alert-dialog {
    z-index: 2000;
  }
  .progress-linear {
    z-index: 2010;
  }
}
img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 0px;
}
.v-snack.v-snack--active.v-snack--bottom {
  z-index: 5000;
}
.v-dialog__content.v-dialog__content--active {
  z-index: 8000 !important;
}
.v-overlay.v-overlay--active.theme--dark {
  z-index: 5000 !important;
}
.leaflet-bottom.leaflet-right {
  .leaflet-control-attribution.leaflet-control {
    pointer-events: painted !important;
  }
}
</style>
