<template>
  <div class="risk-sub-control d-flex">
    <ObservatoryControlLayer />
    <v-card
      class="align-self-start ml-2 pa-2"
      :width="Risk.RIVER_FLOODING.key === selectedRisk.key ? 150 : 100"
      v-if="
        Risk.RAIN.key === selectedRisk.key ||
          Risk.DOSYA.key === selectedRisk.key ||
          Risk.SNOW.key === selectedRisk.key ||
          Risk.RIVER_FLOODING.key === selectedRisk.key
      "
    >
      <LayerOpacityControl />
      <template v-if="Risk.RIVER_FLOODING.key === selectedRisk.key">
        <v-btn-toggle
          v-model="riverFloodingSelectedIndex"
          dense
          mandatory
          @change="selectRiverFlooding()"
        >
          <v-btn x-small v-for="t in riverFloodingTypes" :key="t.code">
            {{ t.name }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-card>
  </div>
</template>

<script>
import { Risk } from "../../../enums/Risk";
import ObservatoryControlLayer from "./ObservatoryControlLayer";
import LayerOpacityControl from "./LayerOpacityControl";

export default {
  name: "RiskControlLayer",
  components: {
    ObservatoryControlLayer,
    LayerOpacityControl
  },
  data() {
    return {
      Risk: Risk,
      riverFloodingSelectedIndex: 0,
      riverFloodingTypes: [
        { code: 3, name: "内外水" },
        { code: 1, name: "内水" },
        { code: 2, name: "外水" }
      ]
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    }
  },
  methods: {
    selectRiverFlooding() {
      const targetType = this.riverFloodingTypes[
        this.riverFloodingSelectedIndex
      ];
      this.$store.commit("SET_RIVER_FLOODING_TYPE", targetType.code);
      this.$store.dispatch("FETCH_RIVER_FLOODING");
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-sub-control {
  width: 300px !important;
}
</style>
<style lang="scss">
.small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}
.small-radio .v-radio {
  padding: 0px;
}
.small-radio [class*="__ripple"] {
  left: 0;
}
</style>
