<template>
  <div v-if="mobileHazardMap.key">
    <template v-if="feature.isShow">
      <l-tile-layer
        layer-type="overlay"
        v-for="(url, key) in feature.urls"
        :key="key"
        :url="url"
        :options="{ opacity: 0.6 }"
      ></l-tile-layer>
    </template>
  </div>
</template>

<script>
import { LTileLayer } from "vue2-leaflet";

export default {
  name: "MobileHazardMapLayer",
  components: { LTileLayer },
  computed: {
    mobileHazardMap() {
      return this.$store.getters.mobileHazardMap;
    },
    feature() {
      return this.mobileHazardMap.detail;
    }
  }
};
</script>
