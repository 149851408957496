import "core-js/stable";
import "regenerator-runtime/runtime";
import cssVars from "css-vars-ponyfill";
cssVars();
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "leaflet/dist/leaflet.css";
import "vue-datetime/dist/vue-datetime.css";
import { Icon } from "leaflet";

Vue.config.productionTip = false;

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
