<template>
  <div class="pa-2">
    <div class="mb-3">
      <div class="caption text--secondary">
        {{ selectedObservatory.address }}
      </div>
      <div class="caption text--secondary">
        {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
      </div>
    </div>
    <v-card>
      <v-row no-gutters justify="center" class="pa-2 current-image-area">
        <v-carousel
          height="300"
          hide-delimiters
          hide-delimiter-background
          v-model="currentImageIndex"
        >
          <template v-if="images.length > 0">
            <v-carousel-item v-for="(imageInfo, index) in images" :key="index">
              <v-img
                height="300"
                :src="imageInfo.url"
                contain
                @click="showImage()"
              />
            </v-carousel-item>
          </template>
          <template v-else>
            <v-carousel-item>
              <v-img
                height="300"
                :src="require('@/assets/images/camera-none.jpg')"
                contain
              />
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-row>
      <div>
        <div class="image-list">
          <div
            v-for="(image, index) in images"
            :key="index"
            :style="{
              width: 100 / (photoCount < 5 ? 5 : photoCount) + '%'
            }"
            :class="{ 'current-image': index === currentImageIndex }"
            class="image-list-item"
            @click="updateCurrentImage(index)"
          >
            <v-img width="100%" height="100%" :src="image.url"></v-img>
            <div class="text">
              <div class="date">
                {{
                  moment
                    .utc(image.date, "YYYY/MM/DD HH:mm")
                    .local()
                    .format("MM/DD")
                }}
              </div>
              <div class="times">
                {{
                  moment
                    .utc(image.date, "YYYY/MM/DD HH:mm")
                    .local()
                    .format("HH:mm")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog
      content-class="camera-dialog"
      v-model="dialog"
      max-width="1100"
      style="z-index: 8100"
      @click="dialog = false"
    >
      <v-img
        v-if="images.length > 0"
        :src="images[currentImageIndex].url"
        contain
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CameraDetail",
  data() {
    return {
      moment,
      currentImageIndex: 0,
      dialog: false
    };
  },
  watch: {
    selectedObservatory() {
      this.currentImageIndex = this.photoCount - 1;
    }
  },
  mounted() {
    this.currentImageIndex = this.photoCount - 1;
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    photoCount() {
      return this.selectedObservatory.cameraData.length;
    },
    isMobile() {
      return this.$route.path === "/mobile";
    },
    images() {
      return this.selectedObservatory.cameraData;
    }
  },
  methods: {
    updateCurrentImage(index) {
      this.currentImageIndex = index;
    },
    showImage() {
      if (this.isMobile === true) {
        const url = this.images[this.currentImageIndex].url;
        window.open(`image.html?url=${url}`);
      } else {
        this.dialog = true;
      }
    }
  }
};
</script>

<style lang="scss">
.camera-dialog {
  box-shadow: none !important;
}

.current-image-area {
  background-color: black;
  max-height: 50vh;
}

.image-list {
  white-space: nowrap;

  .image-list-item {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    background-color: white;
    display: inline-block;
    .text {
      text-align: center;
      padding: 6px 0;
      line-height: 1.1;

      .date {
        font-size: 11px;
      }
      .times {
        padding-top: 3px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .current-image {
    color: white;
    background-color: #0d47a1;
  }
}
</style>
