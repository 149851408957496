<script>
import { Bar } from "vue-chartjs";

import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";

Chart.plugins.register(ChartAnnotationsPlugin);

import moment from "moment";
import _ from "lodash";

export default {
  extends: Bar,
  name: "RainChart",
  data() {
    return {
      totalRains: [],
      rains: [],
      mesuredLatest: null,
      timeSeries: [],
      dataCollection: {
        labels: [],
        datasets: []
      },
      options: {}
    };
  },
  computed: {
    isMobile() {
      return this.$route.path === "/mobile";
    }
  },
  watch: {
    selectedObservatory() {
      if (this.isMobile) {
        return;
      }
      this.resetChartData();
      this.setTimeSeries();
      this.setRainTotalData();
      this.setRainData();
      this.setYAxesLimitData();
      this.setNowStrokeData();
      this.renderChart(this.dataCollection, this.options);
    },
    displayHours() {
      this.resetChartData();
      this.setTimeSeries();
      this.setRainTotalData();
      this.setRainData();
      this.setYAxesLimitData();
      this.setNowStrokeData();
      this.renderChart(this.dataCollection, this.options);
    }
  },
  mounted() {
    this.resetChartData();
    this.setTimeSeries();
    this.setRainTotalData();
    this.setRainData();
    this.setYAxesLimitData();
    this.setNowStrokeData();
    setTimeout(() => {
      this.renderChart(this.dataCollection, this.options);
    }, 500);
  },
  methods: {
    resetChartData() {
      this.totalRains = [];
      this.rains = [];
      this.mesuredLatest = null;
      this.timeSeries = [];
      this.dataCollection = {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "累加雨量",
            unit: "mm",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "totalRain",
            xAxisID: "data",
            borderWidth: 2,
            borderColor: "#91C46C",
            backgroundColor: "#91C46C",
            pointBorderColor: "#91C46C",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: false
          },
          {
            type: "line",
            label: "累加雨量",
            unit: "mm",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "totalRain",
            xAxisID: "data",
            borderWidth: 2,
            borderColor: "#91C46C",
            backgroundColor: "#91C46C",
            pointBorderColor: "#91C46C",
            borderDash: [5, 2],
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: false
          },
          {
            type: "bar",
            label: "10分雨量",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm",
            backgroundColor: "#1C344C",
            tooltipLabelColor: "#1C344C",
            borderColor: "#1C344C",
            pointBackgroundColor: "white",
            borderWidth: 0.5,
            data: [],
            barPercentage: 1.0,
            categoryPercentage: 1.0
          }
        ]
      };
      this.options = {
        layout: {
          padding: {
            right: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              const val =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (val !== null) {
                return label + " : " + val + " " + unit;
              }
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "totalRain",
              position: "left",
              gridLines: {
                display: false
              },
              ticks: {
                max: 20,
                min: 0,
                callback: label => {
                  return parseFloat(label);
                }
              }
            },
            {
              id: "rain",
              position: "right",
              gridLines: {
                display: false
              },
              ticks: {
                max: 20,
                min: 0,
                callback: label => {
                  return parseFloat(label).toFixed(1);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                autoSkip: false,
                callback: tick => {
                  const o = moment(tick, "HH:mm");
                  if (this.displayHours === 36) {
                    return o.minute() % 60 === 0 && o.hours() % 2 === 0
                      ? tick
                      : null;
                  } else {
                    return o.minute() % 60 === 0 ? tick : null;
                  }
                }
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "box",
              drawTime: "beforeDatasetsDraw",
              yScaleID: "totalRain",
              borderColor: "rgba(0,0,0,0)",
              yMin: 0,
              yMax: 0,
              backgroundColor: "#3D8AFF"
            },
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        }
      };
    },
    setTimeSeries() {
      const mesuredData =
        this.selectedObservatory.mesuredData === undefined
          ? []
          : this.selectedObservatory.mesuredData;
      const predictData =
        this.selectedObservatory.predictData === undefined
          ? []
          : this.selectedObservatory.predictData;

      if (mesuredData.length === 0 && predictData.length === 0) {
        return;
      }

      // mesuredLatest
      const latest =
        mesuredData.length > 0
          ? mesuredData[mesuredData.length - 1]
          : predictData[0];
      this.mesuredLatest = {
        jst: moment.utc(latest.date, "YYYY/MM/DD HH:mm").local(),
        total: latest.total,
        min10: latest.min10,
        section: "mesured",
        latest: true
      };

      // create time series
      this.timeSeries.push(Object.assign(this.mesuredLatest));
      [...Array(18).keys()].forEach(i => {
        const date = this.mesuredLatest.jst
          .clone()
          .add(-10 * (i + 1), "minutes");
        this.timeSeries.unshift({
          jst: date,
          total: null,
          min10: null,
          section: "mesured"
        });
      });
      [...Array(this.displayHours * 6).keys()].forEach(i => {
        const date = this.mesuredLatest.jst
          .clone()
          .add(10 * (i + 1), "minutes");
        this.timeSeries.push({
          jst: date,
          total: null,
          min10: null,
          section: "predict"
        });
      });

      // mesured
      mesuredData.forEach(r => {
        const target = moment.utc(r.date, "YYYY/MM/DD HH:mm").local();
        if (target.isBefore(this.mesuredLatest.jst)) {
          const ts = this.timeSeries.find(r => r.jst.isSame(target));
          if (ts !== undefined) {
            ts.total = r.total;
            ts.min10 = r.min10;
          }
        }
      });

      // predict
      predictData.forEach(r => {
        const target = moment.utc(r.date, "YYYY/MM/DD HH:mm").local();
        if (target.isAfter(this.mesuredLatest.jst)) {
          const ts = this.timeSeries.find(r => r.jst.isSame(target));
          if (ts !== undefined) {
            ts.total = this.mesuredLatest.total + r.total;
            ts.min10 = r.min10;
          }
        }
      });

      // labels
      this.dataCollection.labels = this.timeSeries.map(t =>
        t.jst.format("HH:mm")
      );
    },
    setRainTotalData() {
      // mesured
      const mesuredData = this.timeSeries
        .filter(ts => ts.section === "mesured")
        .map(ts => ts.total);
      this.dataCollection.datasets[0].data = mesuredData;

      // predict
      const predictData = this.timeSeries
        .filter(ts => ts.section === "predict")
        .map(ts => ts.total);
      const emptys = Array(mesuredData.length);
      emptys.fill(null).concat(predictData);
      this.dataCollection.datasets[1].data = emptys.concat(predictData);

      // totalRains
      this.totalRains = predictData.concat(mesuredData);
    },
    setRainData() {
      this.rains = this.timeSeries.map(ts => ts.min10);
      this.dataCollection.datasets[2].data = Object.assign(this.rains);
    },
    setYAxesLimitData() {
      let totalMaxLevel = parseFloat(_.max(this.totalRains)) + 4;
      if (totalMaxLevel < 100) {
        totalMaxLevel = 100;
      }
      let maxLevel = parseFloat(_.max(this.rains)) + 1;
      if (maxLevel < 5) {
        maxLevel = 5;
      }
      this.options.scales.yAxes[0].ticks.min = 0;
      this.options.scales.yAxes[0].ticks.max = Math.floor(totalMaxLevel);
      this.options.scales.yAxes[1].ticks.min = 0;
      this.options.scales.yAxes[1].ticks.max = Math.floor(maxLevel);
    },
    setNowStrokeData() {
      if (this.mesuredLatest === null) {
        return;
      }
      const hm = this.mesuredLatest.jst.format("HH:mm");
      this.options.annotation.annotations[1].value = hm;
    }
  },
  props: {
    selectedObservatory: {
      type: Object
    },
    displayHours: {
      type: Number,
      default: 0.5
    }
  }
};
</script>

<style scoped></style>
