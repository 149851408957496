import Vue from "vue";
import VueRouter from "vue-router";
import isMobile from "ismobilejs";
import store from "@/store";
import * as cognito from "../utils/cognito";
import Login from "../views/Login";
import MobileDashboard from "../views/Mobile";
import Dashboard from "../views/Dashboard";
import MobileCameraMonitor from "../views/MobileCameraMonitor";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { notRequiredAuth: true }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiredAuth: true },
    beforeRouteEnter(to, from, next) {
      if (isMobile().any) {
        return next("/mobile");
      } else {
        return next();
      }
    }
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: MobileDashboard,
    meta: { requiredAuth: true }
  },
  {
    path: "/mobile-camera-monitor",
    name: "MobileCameraMonitor",
    component: MobileCameraMonitor,
    meta: { requiredAuth: true }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  if (to.name === "login") {
    if (store.getters.isPublic === true) {
      next({ path: "/" });
    } else {
      next();
    }
    return;
  }
  // スマホの場合にチャット画面にリダイレクト
  if (
    isMobile().any &&
    "fullPath" in to &&
    to.fullPath.indexOf("/mobile") === -1
  ) {
    next({
      path: "/mobile"
    });
    return;
  }
  try {
    if (store.getters.isPublic !== true) {
      const session = await cognito.isAuthenticated();
      const token = session.idToken.jwtToken;
      const user = Object.assign({}, session.idToken.payload);
      user.token = token;
      user.id = user["cognito:username"];
      if (user.name === undefined) {
        user.name = user.id;
      }
      console.log(user.id);
      store.commit("SET_COGNITO_USER_INFO", user);
    }
    if (isMobile().any !== true) {
      store.commit("SET_IS_NAVIGATION", true);
      store.commit("SET_IS_RIGHT_NAV", true);
    } else {
      store.commit("SET_IS_NAVIGATION", false);
      store.commit("SET_IS_RIGHT_NAV", false);
    }
    next();
  } catch (error) {
    // トークン有効期限が切れた場合など
    if (store.getters.isPublic !== true) {
      store.commit("SET_COGNITO_USER_INFO", {});
      cognito.signout();
      next("/login");
    } else {
      next("/");
    }
    return;
  }
});

export default router;
