<template>
  <SlicedImageLayer :bounds="bounds" :images="images" />
</template>
<script>
import SlicedImageLayer from "./SlicedImageLayer";
export default {
  name: "RainLayer",
  components: {
    SlicedImageLayer
  },
  data() {
    return {
      zoomStep: 1 // zoomを３つステップに分けている。zoomが>= 9の場合：1、>= 7 && < 9の場合：2、その他の場合：3
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    bounds() {
      return this.$store.getters.rainImageCoordinate;
    },
    images() {
      return this.$store.getters.rainImageInfo;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.map.on("zoomend", this.loadRainImageByZoomLevel);
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "レーダ・予測雨量\n(mm/h)",
      colors: [
        { color: "#ba206e", value: "80" },
        { color: "#ff3700", value: "50" },
        { color: "#ffa021", value: "30" },
        { color: "#fbf43c", value: "20" },
        { color: "#0041FF", value: "10" },
        { color: "#218CFF", value: "5" },
        { color: "#A0D2FF", value: "1" },
        { color: "#99FFFF", value: "0" }
      ]
    });
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off("zoomend", this.loadRainImageByZoomLevel);
    }
  },
  methods: {
    loadData() {
      this.zoomStep = this.getZoomStep();
      this.$store.dispatch("FETCH_RAIN", { level: this.zoomStep });
    },
    getZoomStep() {
      const currentZoom = this.map.getZoom();
      if (currentZoom >= 9) {
        return 1;
      } else if (currentZoom >= 7 && currentZoom < 9) {
        return 2;
      } else {
        return 3;
      }
    },
    loadRainImageByZoomLevel() {
      const currentZoomStep = this.getZoomStep();
      if (currentZoomStep !== this.zoomStep) {
        this.zoomStep = currentZoomStep;
        this.$store.dispatch("FETCH_RAIN", {
          level: this.zoomStep,
          selectedDate: this.selectedDate
        });
      }
    }
  }
};
</script>
