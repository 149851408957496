<template>
  <SlicedImageLayer :bounds="bounds" :images="images" v-if="images" />
</template>
<script>
import SlicedImageLayer from "./SlicedImageLayer";
export default {
  name: "DosyaLayer",
  components: {
    SlicedImageLayer
  },
  computed: {
    bounds() {
      return this.$store.getters.dosyaInfo.bounds;
    },
    images() {
      return this.$store.getters.dosyaInfo.images;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "土砂災害警戒判定値",
      colors: [
        { color: "#10000e", value: "災害切迫【５】" },
        { color: "#a5009c", value: "危険　　【４】" },
        { color: "#ff251b", value: "警戒　　【３】" },
        { color: "#f1e539", value: "注意　　【２】" },
        { color: "#ffffff", value: "今後の情報等に留意" }
      ]
    });
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_DOSYA");
    }
  }
};
</script>
