<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
Chart.plugins.register(ChartAnnotationsPlugin);
import moment from "moment";
import _ from "lodash";

export default {
  extends: Bar,
  name: "StageChart",
  props: {
    selectedObservatory: {
      type: Object
    },
    chartTimeSeries: {
      type: Array
    },
    mesuredLatestValue: String,
    displayHours: Number,
    standardLevels: [Array, Object]
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: []
      },
      options: {}
    };
  },
  computed: {
    isMobile() {
      return this.$route.path === "/mobile";
    },
    landform() {
      if (
        this.selectedObservatory.landform &&
        this.selectedObservatory.landform.length > 0
      ) {
        return this.selectedObservatory.landform;
      } else {
        return [];
      }
    },
    nowJst() {
      // 10分刻みで秒は考慮しない
      const date = this.$store.getters.baseDate.clone();
      date.subtract(date.minute() % 10, "minutes");
      return moment.utc(date.format("YYYYMMDDHHmm"), "YYYYMMDDHHmm").local();
    },
    mesuredData() {
      return this.selectedObservatory.mesuredData === undefined
        ? []
        : this.selectedObservatory.mesuredData;
    },
    predictData() {
      if (this.$store.getters.isPublic === true) {
        return [];
      }
      return this.selectedObservatory.predictData === undefined
        ? []
        : this.selectedObservatory.predictData;
    }
  },
  watch: {
    selectedObservatory() {
      if (this.isMobile) {
        return;
      }
      this.resetChartData();
      this.setTimeSeries();
      this.setRiverLevelData();
      this.setYAxesLimitData();
      this.setWarningLevelStrokeData();
      this.setNowStrokeData();
      this.setWaterBoxData();
      this.renderChart(this.dataCollection, this.options);
    },
    displayHours() {
      this.resetChartData();
      this.setTimeSeries();
      this.setRiverLevelData();
      this.setYAxesLimitData();
      this.setWarningLevelStrokeData();
      this.setNowStrokeData();
      this.setWaterBoxData();
      this.renderChart(this.dataCollection, this.options);
    }
  },
  mounted() {
    this.resetChartData();
    this.setTimeSeries();
    this.setRiverLevelData();
    this.setYAxesLimitData();
    this.setWarningLevelStrokeData();
    this.setNowStrokeData();
    this.setWaterBoxData();

    this.addPlugin({
      id: "horizontalLine",
      beforeDatasetsDraw: chart => {
        const groundDataset = chart.config.options.groundDataset;
        if (groundDataset.length > 0) {
          const ctxPlugin = chart.chart.ctx;
          const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
          const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
          const minGround = _.minBy(groundDataset, o => {
            return o[0];
          });
          const maxGround = _.maxBy(groundDataset, o => {
            return o[0];
          });
          ctxPlugin.strokeStyle = "red";
          ctxPlugin.beginPath();
          ctxPlugin.moveTo(xAxe.left, yAxe.bottom);
          for (let i = 0; i < groundDataset.length; i++) {
            let x = groundDataset[i][0] - minGround[0];
            x = (x / (maxGround[0] - minGround[0])) * xAxe.width + xAxe.left;
            let y = yAxe.max - yAxe.min - (groundDataset[i][1] - yAxe.min);
            y = (y / (yAxe.max - yAxe.min)) * yAxe.height + yAxe.top;
            ctxPlugin.lineTo(x, y);
          }
          ctxPlugin.lineTo(xAxe.right, yAxe.bottom);
          ctxPlugin.lineTo(xAxe.left, yAxe.bottom);
          ctxPlugin.closePath();
          ctxPlugin.fillStyle = "#f1eeea";
          ctxPlugin.fill();
        }
      }
    });

    setTimeout(() => {
      this.renderChart(this.dataCollection, this.options);
    }, 500);
  },
  methods: {
    resetChartData() {
      this.dataCollection = {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 2,
            borderColor: "#01163F",
            backgroundColor: "#01163F",
            pointBorderColor: "#01163F",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 3,
            borderColor: "#3734dc",
            backgroundColor: "#3734dc",
            pointBorderColor: "#3734dc",
            borderDash: [10, 5],
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          }
        ]
      };
      this.options = {
        layout: {
          padding: {
            right: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "level",
              gridLines: {
                display: false
              },
              ticks: {
                max: 8,
                min: 0,
                callback: label => {
                  return ("        " + parseFloat(label).toFixed(1)).slice(-6);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                autoSkip: false,
                callback: tick => {
                  const o = moment(tick, "MM/DD HH:mm");
                  if (this.displayHours === 36) {
                    return o.minute() % 60 === 0 && o.hours() % 2 === 0
                      ? o.format("HH:mm")
                      : null;
                  } else {
                    return o.minute() % 60 === 0 ? o.format("HH:mm") : null;
                  }
                }
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "box",
              drawTime: "beforeDatasetsDraw",
              yScaleID: "level",
              borderColor: "rgba(0,0,0,0)",
              yMin: 0,
              yMax: 0,
              backgroundColor: "#3D8AFF"
            },
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        }
      };
    },
    setTimeSeries() {
      if (this.mesuredData.length === 0 && this.predictData.length === 0) {
        return;
      }
      this.dataCollection.labels = this.chartTimeSeries.map(ts => {
        return moment
          .utc(ts, "YYYY/MM/DD HH:mm")
          .local()
          .format("MM/DD HH:mm");
      });
    },
    setRiverLevelData() {
      // mesured
      this.dataCollection.datasets[0].data = this.chartTimeSeries.map(ts => {
        if (ts > this.mesuredLatestValue) {
          return null;
        }
        const finded = this.mesuredData.find(r => r.date === ts);
        return finded === undefined || finded.level === undefined
          ? null
          : finded.level.toFixed(2);
      });

      // predict
      this.dataCollection.datasets[1].data = this.chartTimeSeries.map(ts => {
        if (ts <= this.mesuredLatestValue) {
          return null;
        }
        const finded = this.predictData.find(r => r.date === ts);
        return finded === undefined || finded.level === undefined
          ? null
          : finded.level.toFixed(2);
      });

      // landform
      this.options.groundDataset = this.landform;
    },
    setYAxesLimitData() {
      // Y座標側のscaleを設定
      if (
        this.selectedObservatory.landform &&
        this.selectedObservatory.landform.length > 0
      ) {
        // 断面図が存在する場合
        const landformYMin = _.minBy(this.landform, o => {
          return o[1];
        });
        const landformYMax = _.maxBy(this.landform, o => {
          return o[1];
        });
        if (landformYMin) {
          let level = landformYMin[1];
          const mesuredLevel = this.getMesuredLatestLevel();
          if (mesuredLevel !== null && level > mesuredLevel) {
            level = mesuredLevel;
          }
          this.options.scales.yAxes[0].ticks.min = level - 0.5;
          this.options.annotation.annotations[0].yMin = level - 0.5;
        }
        if (landformYMax) {
          this.options.scales.yAxes[0].ticks.max = Math.floor(
            landformYMax[1] + 2
          );
        }
      } else {
        let levels = this.dataCollection.datasets[0].data.concat(
          this.dataCollection.datasets[1].data
        );
        levels = levels.filter(l => l !== null).map(l => parseFloat(l));
        const minLevel = parseFloat(_.min(levels));
        let maxLevel = parseFloat(_.max(levels));
        if (
          this.selectedObservatory.outbreakLevel !== undefined &&
          maxLevel < this.selectedObservatory.outbreakLevel
        ) {
          maxLevel = this.selectedObservatory.outbreakLevel + 2;
        } else if (
          this.selectedObservatory.dangerousLevel !== undefined &&
          maxLevel < this.selectedObservatory.dangerousLevel
        ) {
          maxLevel = this.selectedObservatory.dangerousLevel + 2;
        } else {
          maxLevel += 8;
        }
        this.options.scales.yAxes[0].ticks.min = minLevel - 1;
        this.options.scales.yAxes[0].ticks.max = Math.floor(maxLevel);
      }
    },
    setWarningLevelStrokeData() {
      const levels = this.standardLevels.filter(s => s.value !== undefined);
      levels.forEach(s => {
        this.options.annotation.annotations.push({
          type: "line",
          mode: "horizontal",
          scaleID: "level",
          value: s.value,
          borderColor: s.color,
          borderWidth: 1.5,
          label: {
            backgroundColor: "rgba(0,0,0,0)",
            yAdjust: -6,
            fontSize: 10,
            fontColor: "black",
            position: "right",
            content: "",
            enabled: true
          }
        });
      });
    },
    setNowStrokeData() {
      this.options.annotation.annotations[1].value = moment
        .utc(this.mesuredLatestValue, "YYYY/MM/DD HH:mm")
        .local()
        .format("MM/DD HH:mm");
    },
    setWaterBoxData() {
      const level = this.getMesuredLatestLevel();
      this.options.annotation.annotations[0].yMin = this.options.scales.yAxes[0].ticks.min;
      if (level === null) {
        this.options.annotation.annotations[0].yMax = this.options.scales.yAxes[0].ticks.min;
      } else {
        this.options.annotation.annotations[0].yMax = level;
      }
    },
    getMesuredLatestLevel() {
      if (this.mesuredData.length === 0) {
        return null;
      } else {
        return this.mesuredData[this.mesuredData.length - 1].level;
      }
    }
  }
};
</script>

<style scoped></style>
