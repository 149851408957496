<template>
  <div class="date-time-select-area d-flex flex-row-reverse">
    <div class="fab-update-button ml-1" @click="updateNow()">
      <v-icon size="21">mdi-update</v-icon>
    </div>
    <datetime
      v-model="baseDate"
      input-class="datetime-picker"
      type="datetime"
      @mousedown="showDateTime()"
      :min-datetime="minDate"
      :max-datetime="currentDate"
    >
      <template slot="button-cancel">
        <v-btn text color="error">
          キャンセル
        </v-btn>
      </template>
      <template slot="button-confirm" slot-scope="scope">
        <span v-if="scope.step === 'date'">
          <v-btn icon color="indigo">
            <v-icon>mdi-clock</v-icon>
          </v-btn>
        </span>
        <span v-else>
          <v-btn text color="success">
            確定
          </v-btn>
        </span>
      </template>
    </datetime>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MobileBasetimeControl",
  data() {
    return {
      currentDate: moment().toISOString(),
      minDate: moment.utc("2022/06/30 15:00", "YYYY/MM/DD HH:mm").toISOString(),
    };
  },
  computed: {
    baseDate: {
      get() {
        return this.$store.getters.baseDate.toISOString();
      },
      set(date) {
        this.$store.commit("SET_BASE_DATE", moment.utc(date));
      }
    }
  },
  methods: {
    updateNow() {
      this.$store.commit("SET_BASE_DATE", moment().utc());
    },
    showDateTime() {
      this.currentDate = moment().toISOString();
    }
  }
};
</script>

<style lang="scss">
.date-time-select-area {
  pointer-events: painted !important;
}
.fab-update-button {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background-color: rgb(255, 190, 61);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
}
</style>
