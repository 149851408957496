<template>
  <l-geo-json
    :geojson="outlineGeojson"
    :optionsStyle="{
      weight: 2,
      color: '#000000',
      opacity: 1,
      fillColor: 'rgba(0,0,0,0)',
      fillOpacity: 0.2
    }"
  ></l-geo-json>
</template>

<script>
import { LGeoJson } from "vue2-leaflet";
import axios from "axios";

export default {
  name: "CityBorderLayer",
  components: {
    LGeoJson
  },
  data() {
    return {
      outlineGeojson: null
    };
  },
  computed: {
    selectedBaseTile() {
      return this.$store.getters.selectedBaseTile;
    }
  },
  created() {
    axios
      .get(
        "https://s3.ap-northeast-1.amazonaws.com/ctie.tfd.web/hachioji/geojson/outline.geojson"
      )
      .then(response => {
        this.outlineGeojson = response.data;
      });
  }
};
</script>
